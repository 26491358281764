/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="">
        <table class="table table-hover table-dark">
            <thead>
              <tr>
                <th scope="col" style="margin-left: -10px;">Machine</th>
                <th scope="col" style="margin-left: -10px;">Machine<br>Status</th>
                <th scope="col" style="margin-left: -10px;">Current<br>Product</th>
                <th scope="col" style="margin-left: -10px;">Meters/<br>Minute</th>
                <th scope="col" style="margin-left: -10px;">Thresh</th>
                <th scope="col" style="margin-left: -10px;">Sensor<br>Status</th>
                <th scope="col" style="margin-left: -10px;" v-if="userType != 'viewer'">View</th>
              </tr>
            </thead>
            <tbody>
              <tr 
                v-for="machine in corrugatorss" 
                :key="machine.id" 
                id="machine_row" 
                class="machine_status"
                :class="statusFomart(machine)">
                <td class="machine_name" style="margin-left: -10px;">{{ machine.machineName }}</td>
                <!--MACHINE STATUS -->
                <td>
                  <select 
                    :disabled="disabled"
                    :class="statusFomart(machine)"
                    v-model="machine.machine_state" 
                    @change="machine_config(machine)" >
                    <option disabled selected>{{ machine.machine_state  }}</option>
                    <option v-for="status in machine_status" :key="status.id">{{ status.name }}</option>
                  </select>
                </td>
                <!--METERS PER MIUTE -->
                <td>
                  <select 
                    :class="statusFomart(machine)"
                    v-model="machine.product" 
                    :disabled="disabled"
                    @change="product_config(machine)" >
                    <option disabled selected>{{ machine.product  }}</option>
                    <option v-for="product in products" :key="product.id">{{ product.name }}</option>
                  </select>
                </td>

                <td style="margin-left: -10px;" 
                v-if="parseFloat((machine.deltaPulses / machine.pulseConfig) / machine.differenceInSeconds).toFixed(2) < 0
                || 
                new Date().getTime() / 60000 - new Date(machine.updatedDate).getTime() / 60000 > machine.comm_interval">0.00</td>

                <td style="margin-left: -10px;" v-else>{{ parseFloat((machine.deltaPulses / machine.pulseConfig) / machine.differenceInSeconds).toFixed(2) }}</td>
                <!--BELOW THRESH -->
                <td style="margin-left: -10px;">{{ machine.threshold }}</td>
                <!--STATUS -->
                <td style="margin-left: -10px;" v-if="new Date().getTime() / 60000 - new Date(`${machine.updatedDate} Z`).getTime() / 60000 < machine.comm_interval">On</td>
                <td style="margin-left: -10px;" v-else>Off</td>
                <td>
                <i class="fa fa-eye" @click="corrugatorChart(machine.machineName)" v-if="userType != 'viewer'"></i>
                </td>
              </tr>
            </tbody>
          </table>
     </div>
</template>
<script>
import axios from 'axios'
export default {
  data() {
  return {
    mechines: [],
    corrugators: [],
    DeviceId: '',
    firtRaw: '',
    secondRaw: '',
    compare: '',
    meterPerMin: '',
    machineStatus: 1,
    sensorStatus: '',
    interval: null,
    machine_status: [],
    userType: '',
    disabled: false
    }
  },

computed: {
  corrugatorss() {
    return this.$store.state.machines.corrugator
  },

  products() {
    return this.$store.state.products.corrugatorProduct
  }
  
},

mounted() {
  this.allstatus()
  this.userType = localStorage.getItem('userType')
  if(this.userType == 'viewer')
  {
    this.disabled = true
  }
},

methods: {
    //GET USER DETAILS
getUserDetails() {
  let userDetails = localStorage.getItem('userType')
  this.userType = userDetails
},

statusFomart(machine) {
  let oldDateTime = new Date(machine.updatedDate + 'Z');
  let currentTime = new Date();
  currentTime = currentTime.getTime();
  oldDateTime = oldDateTime.getTime();
  var threshhold_status = (machine.deltaPulses / machine.pulseConfig / machine.differenceInSeconds) >= machine.threshold
  //console.log(threshhold_status)
  let On;
  let machineOn;
  let Of

  if((currentTime / 1000) - (oldDateTime / 1000) < machine.comm_interval && threshhold_status == true) 
  {
    On = true
  }
  if((currentTime / 1000) - (oldDateTime / 1000) > machine.comm_interval) 
  {
    On = false
  } else if(machine.status == 0 && (currentTime / 1000) - (oldDateTime / 1000) > machine.comm_interval) {
    Of = true
  }else if (machine.status == 0 || threshhold_status == false && (currentTime / 1000) - (oldDateTime / 1000) < machine.comm_interval){
    machineOn = true
  }
  return {
    yellow: machineOn,
    danger: !On,
    success: On,
    Off: Of
  }
},

  corrugatorChart(machineName) 
    {
     this.$router.push('/ExtruderCharts/' + machineName)
    },

product_config(machine) {
        let formData = new FormData()
        let action = 'productUpdate'
        let fullname  = localStorage.getItem('fullname')
        let description = fullname+" Changed product for " +  machine.position + " to " + machine.product

        formData.append('action', action);
        formData.append('fullname', fullname)
        formData.append('description', description)
        formData.append('action', action);
        formData.append('machine_name', machine.position)
        formData.append('product', machine.product)
        axios(
          {
            url: 'https://m4a.ramacsystems.com/backend/mechines/machine_config/machine_config.php',
         //   url: 'http://m4abackend/mechines/machine_config/machine_config.php',
            method: 'post',
            data: formData,
            config: { headers: { 'Content-Type' : 'multipart/formdata'}},
          })
          .then(() => {
            return this.$store.state.machines.corrugators
          })
      },
      
      machine_config(machine) {
        let formData = new FormData()
        let action = 'statusUpdate'
        let fullname  = localStorage.getItem('fullname')
        let description = fullname+" Changed " +  machine.position + " state to " + machine.machine_state

        formData.append('action', action);
        formData.append('fullname', fullname)
        formData.append('description', description)
        formData.append('action', action);
        formData.append('machine_name', machine.position)
        formData.append('machine_state', machine.machine_state)
        axios(
          {
            url: 'https://m4a.ramacsystems.com/backend/mechines/machine_config/machine_config.php',
            //url: 'http://m4abackend/mechines/machine_config/machine_config.php',
            method: 'post',
            data: formData,
            config: { headers: { 'Content-Type' : 'multipart/formdata'}},
          })
          .then(() => {
            // this.closeModal()
            this.getMechines()
          })
      },     
      
 //SELECT ALL STATUS
  allstatus() {
       axios.get('https://m4a.ramacsystems.com/backend/mechines/machine_config/machine_config.php')
        //axios.get('http://m4abackend/mechines/machine_config/machine_config.php')
          .then((res) => {
            this.machine_status = res.data
          })
          .catch(function (error) {
              console.log(error)
          })
      }
 },
}
</script>
<style scoped>
tbody tr:hover,
tbody tr:active {
  background-color: #A9A9A9;
}

.Off {
  background-color: red;
  color: white;
  }

.machine_status._0 {
  background-color: orange;
  color: #fff;
  }

.machine_status._1 {
  background-color: green;
  color: #fff;
  }

.On {
  background-color: green;
  color: white;
  }

tr {
   line-height: 15px;
   min-height: 15px;
   height: 15px;
   word-wrap: break-word;
   width: 10px;
}

.success {
  background-color: green;
}

.danger {
  background-color: red;
}

.yellow {
  background-color: orange;
}

.threshold {
  background-color: orange;
}

select {
   min-height: 10px;
   height: 15px;
   word-wrap: break-word;
   max-width: 80%;
   color: white;
   border: none;
}

</style>