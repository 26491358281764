import axios from 'axios'

const state = {
    prds: [],
    extruderProduct: [],
    compressionMoldProduct: [],
    injectionMouldProduct: [],
    corrugatorProduct: []
}

const getters = {
    productList: state => state.productList,
}

const actions = {
    async fetchAllProducts({commit}) 
    {
        const res = await axios.get('https://m4a.ramacsystems.com/backend/products/products.php')
         var allProducts = res.data
         commit('setAllProducts', allProducts)
         commit('setExtruderProduct', allProducts.filter(product => product.machine_type == "Extruder"))
         commit('setCompressionMoldProduct', allProducts.filter(product => product.machine_type == "Compression Mould"))
         commit('setInjectionMouldProduct', allProducts.filter(product => product.machine_type == "Injection Mould"))
         commit('setCorrugatorProduct', allProducts.filter(product => product.machine_type == "Corrugator"))
    }
}

const mutations = {
    setAllProducts: (state, products) => (
        state.products = products
    ),

    setExtruderProduct(state, extruderProduct) {
        state.extruderProduct = extruderProduct
    },
    setCompressionMoldProduct(state, compressionMoldProduct) {
        state.compressionMoldProduct = compressionMoldProduct
    },
    setInjectionMouldProduct(state, injectionMouldProduct) {
        state.injectionMouldProduct = injectionMouldProduct
    },
    setCorrugatorProduct(state, corrugatorProduct) {
        state.corrugatorProduct = corrugatorProduct
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}