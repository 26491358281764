<template>
  <div class="mechines bg-secondary" v-if="userType == 'Super Admin'">
  <h1 class="">Audit Logs</h1>
  <table class="table table-hover table-dark">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">User</th>
      <th scope="col">Action</th>
      <th scope="col">Description</th>
      <th scope="col">Date Time</th>
    </tr>
  </thead>
  <tbody>
    <tr 
        v-for="(audit, index) in audits" 
        :key="audit.id">
      <td>{{ index+1 }}</td>
      <td>{{ audit.user }}</td>
      <td>{{ audit.user_action }}</td>
      <td>{{ audit.description }}</td>
      <td>{{ audit.date_time }}</td>
    </tr>
  </tbody>
</table>
  </div>
</template>

<script>
import axios from 'axios'
export default {
    data () {
      return {
        audits: [],
        userType: ''
      }
    },
  mounted() {
   this.allAudit()
   this.getUserDetails()
  },

  methods: {
   //GET USER DETAILS
    getUserDetails() {
      let userDetails = localStorage.getItem('userType')
      this.userType = userDetails
    },

    //SELECT ALL AUDITS
    allAudit() {
      axios.get('https://m4a.ramacsystems.com/backend/audit_log/audit.php')
        .then((res) => {
          this.audits = res.data
          // console.log(this.users)
        })
        .catch(function (error) {
            console.log(error)
        })
    },
  },
}
</script>
<style scoped>
.mechines,
h1,
td,
th {
    color: rgb(255, 255, 255);
}

h1 {
    float: left;
    margin-left: 10px;
    text-transform: uppercase;
    font-size: 20px;
}

#addUser {
  float: right;
  margin-right: 90px;
}

.btn {
  margin: -10px 10px -10px 0px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #5bc0de;
}

input:focus + .slider {
  box-shadow: 0 0 1px #5bc0de;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
