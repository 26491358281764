<template>
  <div class="mechines bg-secondary">
  <h1 class="">Mechines Notes</h1>
  <button @click="getNotifications()" class="btn-sm btn-info" id="addUser">Test</button>
  <table class="table table-hover table-dark">
  <thead>
    <tr>
      <!-- <th scope="col">ID</th> -->
      <th scope="col">Machine Name</th>
      <th scope="col">notifications</th>
      <th scope="col">date_received</th>
      <th scope="col">Sensor notes</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="machine in machine_notes" :key="machine.ramac_device">
      <td>{{machine.machine_name}}</td>
      <td v-if="machine.machine_status == '0'">Yes</td>
      <td v-else>No</td>
      <td>{{machine.date_received}}</td>
      <td v-if="machine.sensor_status == '0'">Yes</td>
      <td v-else>No</td>
      <td>
       <button tag="button" class="btn btn-info btn-sm"><i class="fa fa-pen"></i></button>
      <button class="btn btn-info btn-sm" @click="deleteUser(user.id)"><i class="fa fa-trash-alt"></i></button>
      </td>
    </tr>
  </tbody>
</table>
  </div>
</template>

<script>
import axios from 'axios'
//import {mapActions, mapGetters, mapMutations} from 'vuex'
export default {
    data () {
      return {
        machine_notes: []
      }
    },

  mounted() {
      this.getNotifications()
  },

  methods: {
  getNotifications() {
      axios.get('http://m4a.ramacsystems.com/backend/notifications/pc1_notification.php')
        .then((res) => {
          this.machine_notes = res.data
         
          console.log(this.machine_notes)
        })
        .catch(function (error) {
            console.log(error)
        })
   },
  },
}
</script>
<style scoped>
.mechines,
h1,
td,
th {
    color: rgb(255, 255, 255);
}

h1 {
    float: left;
    margin-left: 10px;
    text-transform: uppercase;
    font-size: 20px;
}

#addUser {
  float: right;
  margin-right: 90px;
}

button {
  margin: -10px 10px -10px 0px;
}
</style>