<template>
  <div id="" class="main">
    <!-- PAGE ALERT-->
       <div class="alert" role="alert" id="snackbar">
        <strong>Error:</strong> There is no data for the selected date range.
      </div>
    <!-- FILTER -->
    <form>
        <input id="startDate" type="date"/> <input id="time" type="time" step="2" name="appt-time"/>TO
        <input id="endDate" type="date"/> <input id="time1" type="time" step="2" name="appt-time"/> 

       <button type="button" class="btn btn-info btn-sm" @click="getNewData()">GetData/Date</button>
    </form>
   <!-- CHARTS -->
      <div id="charts" class="flex-container" v-for="detail in machine_details" :key="detail.id">
        <div class="machine_charts bg-dark">
          <!-- PRODUCT GRAPH -->
        <div class="compressionMold">
          <div class="compress_heading">
            <h4>{{ detail.machineName }} MONITERING</h4>
          </div>
          <div class="compress_cotents">
            <canvas id="my-chart" class="bg-light" width="500" height="120"></canvas>
          </div>
        </div>
        </div>
        <div class="machine_details bg-dark">
          <div class="machine_details_heading">
            <h4 class="machine_name"> {{ detail.position }}</h4>
          </div>
          <div class="machine_details_cotents">
            <div class="row">
              <div class="col-sm-1"><h4>RAMAC:</h4></div>
              <div class="col-sm-1" style="text-transform: uppercase;"><p>{{detail.ramacDevice}}</p></div>
            </div>
            <div class="row">
              <div class="col-sm-1"><h4>Sensor Status:</h4></div>
                <div class="col-sm-1" v-if="new Date().getTime() / 60000 - new Date(detail.updatedDate).getTime() / 60000 < 15"><p class="on">ON</p></div>
                <td v-else><p class="sensor_off">Off</p></td>
            </div>
            <div class="row">
              <div class="col-sm-1"><h4>Current Product:</h4></div>
                <div class="" style=""><p>{{ curr_product }}</p></div>
            </div>
            <div class="row">
              <div class="col-sm-1"><h4>Total Products:</h4></div>
              <div class=""><p class="totalmeter">{{ (total / 1 | 0) }}</p></div>
            </div>
          </div>
        </div>
      </div>
      <!-- WEEKLY TABLE DISPLAY -->
      <div class="machine_weekly">
       <!-- FILTER -->
        <form class="machine_weekly_filter">
            <input id="weekly_startDate" type="date" @change="getMachineNewWeeklyData()"/>TO
            <input id="weekly_endDate" type="date" @change="getMachineNewWeeklyData()"/> 
          <!-- EXPORT  DATA TO EXCEL -->
          <json-excel 
          @click="getMachineNewWeeklyData()" 
          :data="dataForExcel"  
          class="btn btn-info btn-sm">Export</json-excel>
        </form>
        <br>
        <br>
        <div class="machines bg-dark">
          <br>
            <h4 class="table_heading">Machines History/Week</h4>
            <table class="table table-hover table-dark">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Product</th>
                <th scope="col">Date</th>
                <th scope="col">Products</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(history, index) in machine_data" :key="history.DateTimeReceived">
                <td>{{ index +1 }}</td>
                <td>{{ history.curr_product }}</td>
                <td>{{ history.DateTimeUTC }}</td>
                <td>{{ (history.compressProducts | 0) }}</td>
              </tr>
            </tbody>
          </table>
      </div>
      </div>
   </div>   
</template>

<script>
import Chart from 'chart.js'
import JsonExcel from 'vue-json-excel'
import axios from 'axios'
import $ from 'jquery'
import ChartAnnotationsPlugin from 'chartjs-plugin-annotation'
Chart.plugins.register(ChartAnnotationsPlugin)
export default {
    data: () => {
    return {
      from_date: '',
      to_date: '',
      ramacDevice: '',
      chartData1: [],
      chartData2: [],
      chartData3: [],
      newChartData: [],
      deviceMessages: [],
      machineName: '',
      dates: [],
      dates1: [],
      newDates: [],
      timeEpoch: [],
      machine_details: [],
      machines: [],
      device_id: '',
      chart: '',
      chartNew: '',
      chartNew2: '',
      dataForExcel: [],
      dataForExcel1: [
      {
        Name: ""
      }
      ],
      Totalmeters: [],
      ctx: '',
      ctx2: '',
      startDate: '',
      converted: [],
      threshold: '',
      total: '',
      material_details: [],
      machine_data: [],
      convert_state: '',
      avg_time: '',
      curr_product: '',
      comm_interval: ''   
    };
  },

  components: {
    JsonExcel,
  },

  created() {
      $(document).ready(function(){
    })
  },

mounted() {
  this.getMechine();
 },
 
methods: {
 //GET MANCHINE
   getMechine() {
     axios.get('https://m4a.ramacsystems.com/backend/mechines/addGet.php')
        .then((res) => {
          this.mechines = res.data
          this.machine_details = this.mechines.filter(machine => machine.machineName == this.$route.params.machineName);
          this.ramacDevice = this.machine_details[0].ramacDevice
          this.getData()
          this.getMachineWeeklyData();
        })
        .catch(function (error) {
            console.log(error)
        })
   },

//API CALL AND DATA FORMART
    getData() {
      //CLEAR CHAT 
      if (this.ctx){
         this.chartData = [],
         this.dates = []
       }
      //FORMAT DATE FILTER
      var d = new Date();
      var endDate = new Date();
      endDate.setDate(endDate.getDate())
      let time = "00" + ":" + "00" + ":" + "00";
      let time1 = "23" + ":" + "59" + ":" + "00";
      document.getElementById('startDate').valueAsDate = d;
      document.getElementById('endDate').valueAsDate = endDate;
      document.querySelector('input[id="time"]').value = time;
      document.querySelector('input[id="time1"]').value = time1;
      this.to_date = new Date(document.getElementById("endDate").value + " " + document.getElementById('time1').value).toISOString();
      let machineName = this.$route.params.machineName
      this.from_date = new Date(document.getElementById('startDate').value + " "+ document.getElementById('time').value).toISOString();
       //API CALL
        axios.get('https://m4a.ramacsystems.com/backend/mechines/machine_charts/compressionChart.php?params=', {
          params: {
             machineName,
             from_date: this.from_date,
             to_date: this.to_date
          }
        })
        //RESPONSE AND RENDER CHART
        .then((response) => {
           this.deviceMessages = response.data
          //AVERAGE CAL !!NOTE MISSING THE FIRST VALUE ON THE AVD CAL. AVG OUT BY 0.5%
          const d = this.deviceMessages.filter(diff => diff.DigitalState1Change != "") && this.deviceMessages.filter(diff => diff.diffMinuteD1 > 1);
          this.avg_time = (d.reduce((total, next) => total + next.diffMinuteD1 / 1, 0) / d.length).toFixed(2);
          //CURRENT PRODUCT AND COMMUNICATION
          if(this.deviceMessages.length == 0) {
            this.curr_product = ''
            this.comm_interval = ''
          } else {
            this.curr_product = this.deviceMessages[this.deviceMessages.length - 1]['curr_product']
            this.comm_interval = this.deviceMessages[this.deviceMessages.length - 1]['comm_interval']
          }
          //CHART DATA 
          for (let i = 0; i < this.deviceMessages.length; i++) {
            //IGNORE IF VALUES ARE EMPTY
            if(this.deviceMessages[i].DigitalState1Change != "") {
              //SWAP VALUES AROUND
              if(this.deviceMessages[i].DigitalState1Change == 0) {
                 this.deviceMessages[i].DigitalState1Change = 1
                 this.chartData1.push(this.deviceMessages[i].DigitalState1Change)
              } 
              else if(this.deviceMessages[i].DigitalState1Change == 1) {
                 this.deviceMessages[i].DigitalState1Change = 0
                 this.chartData1.push(this.deviceMessages[i].DigitalState1Change)
              } 
            }
           if(this.deviceMessages[i].DigitalState1Change != "") 
            {
            this.dates.push(this.deviceMessages[i].DateTimeReceived)
            }
           }
            //console.log(d)
          //TOTAL METERS
            this.total = this.deviceMessages.reduce((sum, item) => sum + item.DigitalState1Change / 1, 0).toFixed(2)
          // console.log(this.total)
          setTimeout(() => {
              this.chartInt();
            }, 1000)
       })
          .catch(error => console.log(error))
     },

//API CALL AND NEW DATA FORMART
     getNewData() {
       if (this.ctx){
            this.chartData = []
            this.dates = []
            this.chartData1 = []
            this.dates1 = []
          }
          this.ctx.data.labels.pop();
          this.ctx.data.datasets.forEach((dataset) => {
              dataset.data.pop();
          });
       this.ctx.destroy(); 
       let to_date = new Date(document.getElementById("endDate").value + " " + document.getElementById('time1').value).toISOString();
       let machineName = this.$route.params.machineName
       let from_date = new Date(document.getElementById('startDate').value + " "+ document.getElementById('time').value).toISOString();
       axios.get('https://m4a.ramacsystems.com/backend/mechines/machine_charts/compressionChart.php?params=', {
      // axios.get('http://m4abackend/mechines/pc2/compressionChart.php?params=', {
          params: {
             machineName,
             from_date,
             to_date
          }
        })
        //RESPONSE AND RENDER CHART
        .then((response) => {
          this.deviceMessages = response.data
          //AVERAGE CAL !!NOTE MISSING THE FIRST VALUE ON THE AVD CAL. AVG OUT BY 0.5%
          const d = this.deviceMessages.filter(diff => diff.DigitalState1Change != "") && this.deviceMessages.filter(diff => diff.DigitalState1Change != 1);
          this.avg_time = (d.reduce((total, next) => total + next.diffMinuteD1 / 1, 0) / d.length).toFixed(2);

          //CURRENT PRODUCT AND COMMUNICATION
          if(this.deviceMessages.length == 0) {
            this.curr_product = ''
            this.comm_interval = ''
            var x = document.getElementById("snackbar");
            x.className = "show";
            setTimeout(function(){ 
            x.className = x.className.replace("show", "");
             }, 2000);
          } else {
            this.curr_product = this.deviceMessages[this.deviceMessages.length - 1]['curr_product']
            this.comm_interval = this.deviceMessages[this.deviceMessages.length - 1]['comm_interval']
          }

          //CHART DATA 
          for (let i = 0; i < this.deviceMessages.length; i++) {
            //IGNORE IF VALUES ARE EMPTY
            if(this.deviceMessages[i].DigitalState1Change != '') 
             {
              //SWAP VALUES AROUND
              if(this.deviceMessages[i].DigitalState1Change == 0) 
              {
                 this.deviceMessages[i].DigitalState1Change = 1
                 this.chartData1.push(this.deviceMessages[i].DigitalState1Change)
              } 
              else if(this.deviceMessages[i].DigitalState1Change == 1) {
                 this.deviceMessages[i].DigitalState1Change = 0
                 this.chartData1.push(this.deviceMessages[i].DigitalState1Change)
              } 
            }
           if(this.deviceMessages[i].DigitalState1Change != "") {
            //this.dates.push(new Date(pulses[i].DateTimeUTC + 'Z').toLocaleString('en-GB'))
            this.dates.push(this.deviceMessages[i].DateTimeUTC)
             }
           }
          //TOTAL METERS
          this.total = this.deviceMessages.reduce((sum, item) => sum + item.DigitalState1Change / 1, 0).toFixed(2)
          setTimeout(() => {
              this.chartInt();
            }, 1000)
      })
        .catch(error => console.log(error))
     },

  //API CALL GET DATA PER WEEK
    getMachineWeeklyData() {
      var d = new Date();
      var endDate = new Date();
      endDate.setDate(endDate.getDate())
      d.setDate(d.getDate() - 7)
      let time1 = "23" + ":" + "59" + ":" + "59";
      document.getElementById('weekly_startDate').valueAsDate = d;
      document.getElementById('weekly_endDate').valueAsDate = endDate;
      document.querySelector('input[id="time1"]').value = time1;
      this.weekly_to_date = document.getElementById("weekly_endDate").value + " " + document.getElementById('time1').value;
      this.weekly_from_date = document.getElementById('weekly_startDate').value
      let ramac_device = this.machine_details[0].DeviceId
      axios.get('https://m4a.ramacsystems.com/backend/mechines/machine_charts/compressionWeekly.php?params=', {
        params: {
          ramac_device,
          from_date: this.weekly_from_date,
          to_date: this.weekly_to_date
        }
      })
      .then((response) => {
        this.machine_data = response.data
      //DATA FOR THE EXCEL REPORT
        this.dataForExcel = this.machine_data.map(v =>
        ({
          Product: v.curr_product,
          Date: v.DateTimeUTC,
          Products: v.compressProducts
        }))
      })
    },

  //API CALL GET DATA PER WEEK UPDATE
    getMachineNewWeeklyData() {
      let time1 = "23" + ":" + "59" + ":" + "59";
      document.querySelector('input[id="time1"]').value = time1;
      this.weekly_to_date = document.getElementById("weekly_endDate").value + " " + document.getElementById('time1').value;
      this.weekly_from_date = document.getElementById('weekly_startDate').value
      let ramac_device = this.machine_details[0].DeviceId
      axios.get('https://m4a.ramacsystems.com/backend/mechines/machine_charts/compressionWeekly.php?params=', {
        params: {
          ramac_device,
          from_date: this.weekly_from_date,
          to_date: this.weekly_to_date
        }
      })
      .then((response) => {
        this.machine_data = response.data
        this.dataForExcel = this.machine_data.map(v =>
        ({
          Product: v.curr_product,
          Date: v.DateTimeUTC,
          Products: v.compressProducts,
        }))
      })
    },

  //CHART INITIALIZE
   chartInt() {
    this.chartNew = document.getElementById('my-chart').getContext("2d")
    this.ctx = new Chart( this.chartNew, {
      type: 'line',
      data: {
        labels:  this.dates,
        datasets: [
          {
          label: 'Open',
          lineTension: 0,
          steppedLine: 'after',
          borderColor: '#0585e1',
          pointBackgroundColor: '#85998a',
          pointBorderColor: '#85998a',
          borderWidth: 1,
          backgroundColor: '#bdd9e4',
          data: this.chartData1,
          },
        ]
      },
    options: {
        responsive: true,
            hover: {
              mode: null
              },
            scales: {
              xAxes: [ {
                scaleLabel: {
                  display: true,
                  fontColor: '#000',
                  fontSize: '15',
                  labelString: 'FROM'+' '
                  +document.getElementById('startDate').value + " "+ document.getElementById('time').value
                  +' '+'TO'+' '
                  +document.getElementById("endDate").value + " " + document.getElementById('time1').value
                },
                ticks: {
                display: false,                
                major: {
                    fontStyle: 'bold',
                    fontColor: '#FF0000',
                  }
                }
              } ],
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                  min: 0
                },
                scaleLabel: {
                  fontColor: '#000',
                  fontSize: '15',
                  display: true,
                  labelString: 'DOOR OPEN AND CLOSE'
                }
              }]
              },
            annotation: {
                drawTime: 'afterDatasetsDraw',
                annotations: [{
                    id: 'hline',
                    type: 'line',
                    mode: 'horizontal',
                    scaleID: 'y-axis-0',
                    value: this.threshold,
                    borderColor: '#659EC7',
                    borderWidth: 3,
                }]
            }
         }
      });
    },
  }
}
</script>
<style scoped>
#snackbar {
	visibility: hidden;
	min-width: 250px;
    margin-left: -125px;
    text-align: center;
    background-color: #ff9966 !important;
	border-radius: 2px;
	padding: 5px;
	position: fixed;
	z-index: 1;
	left: 50%;
    top: 10px;
	font-size: 17px;
  }
  
  #snackbar.show {
	visibility: visible;
	-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
	animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }

h1 {
   float: left;
   margin-left: 10px;
   color: black;
   text-transform: uppercase;
   font-size: 20px;
}

form {
   float: right;
   }
input {
  border-radius: 7px;
  border: solid 1px lightblue;
}
.btn {
  margin-left: 10px;
}

canvas {
  background-color: rgb(20, 43, 85);
  padding-right: 10px;
}

/* HEADINGS */
.compress_heading
 h4 
 {
  color: #fff;
}
.machine_details_heading 
h4 
{
  color: #fff;
  text-align: center;
}

/*CONTAINTER STYLE */
.flex-container 
{
  display: flex;
  margin-top: 10px;
  width: 100%;
}

.flex-container  
.machine_charts 
{
  margin: 10px;
  padding: 20px;
  width: 80%;
}

.flex-container  
.machine_details 
{
  margin: 10px;
  padding: 20px;
  width: 20%;
}

.machine_details_cotents 
{
  background-color: #fff;
  /* height: 94%; */
}

.machine_details_cotents 
h4 
{
  font-size: 15px;
  margin: 3px 20px 0 0;
  font-weight: bold;
}

h4 
{
  text-transform: uppercase;
}
p 
{
  margin: 0 0 0 10px;
  font-weight: bold;
  width: 200px;
  float: left;
}

  .on 
  {
    color: green;
  }

  .off 
  {
    color: rgb(255, 208, 0);
  }
  .sensor_off 
  {
    color: red;
  }

.col-sm-1 
.totalmeter 
{
  margin-top: 7px;
} 
.machine_name 
{
  word-wrap: break-word;
  width: 220px;
} 

.material_table 
{
  width: 100%;
}

small 
{
  padding-left: 10px;
}

.material_change 
{
  margin-top: 20px;
}

@media only screen and (max-width: 1920px) 
{
  /* #my-chart,
  #my-chart2
   {
    height: 120px !important;
  } */
  /* .main
   {
    height: 120px !important;
    width: 10% !important;
  } */
}

.col-sm-1 
{
  margin-bottom: 5px;
}
.machines
 {
  width: 98.8%;
  margin-left: 10px;
}

.curr_product 
{
    word-wrap: none;
    width: 100% !important;
}

/*CIRCLE CSS*/
#loader 
{
  visibility: hidden;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 90px;
  height: 90px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
	position: fixed;

  margin-left: 40%;
  margin-top: -2%;
}

  #loader.show 
  {
	visibility: visible;
  }

/* Safari */
@-webkit-keyframes spin 
{
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin 
{
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>