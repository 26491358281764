<template>
  <div class="mechines bg-secondary">
  <h1 class="">Main Factory Machines</h1>
  <router-link tag="button" to="/AddMechine" class="btn-sm btn-info" id="addMechine"  v-if="userType == 'Super Admin' || userType  == 'Admin'">Add Mechine</router-link>
      <table class="table table-hover table-dark">
      <thead>
        <tr>
          <th scope="col">Machine</th>
          <th scope="col">Position</th>
          <th scope="col">Status</th>
          <th scope="col">Site</th>
          <th scope="col">RamacDevice</th>
          <th scope="col">Current Product</th>
          <th scope="col">LastSeen</th>
          <th scope="col"  v-if="userType == 'Super Admin' || userType  == 'Admin' || userType  == 'Manager'">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="machine in compressionMolds" :key="machine.id"  id="machine_row"  class="machine_status" :class="machine.status">
          <td>{{ machine.machineName }}</td>
          <td>{{ machine.position }}</td>
           <td id="qty">{{ machine.machine_state }}</td>
          <td id="qty">{{ machine.site }}</td>
          <td>{{ machine.ramacDevice }}</td>
          <td>{{ machine.product }}</td>
          <td>{{ machine.updatedDate }}</td>
          <td>
            <i  v-if="userType == 'Super Admin' || userType  == 'Admin'" class="fa fa-eye btn btn-info" @click="gotToDetails(machine.machineName)"></i>
            <router-link
               v-if="userType == 'Super Admin' || userType  == 'Admin' || userType  == 'Manager'" 
              tag="i" 
              :to="{ name: 'CompressionConfig', params: { machine }}" 
              class="btn btn-info fa fa-cog">
              <span class="tooltiptext">Machine configuration</span>
            </router-link>
            <router-link 
                v-if="userType == 'Super Admin' || userType  == 'Admin'"
               tag="i" 
              :to="{ name: 'EditCompression', params: { machine }}" 
              class="btn btn-info fa fa-pen">
              <span class="tooltiptext">Edit machine details</span>
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios'
export default {
data() {
  return {
    machines: [],
    compressionMolds: [],
    userType: ''
  }
},

mounted () {
  this.getMechines();
  this.getUserDetails();
},

methods: {
  //GET USER DETAILS
    getUserDetails() {
      let userDetails = localStorage.getItem('userType')

      this.userType = userDetails
      //console.log(userDetails)
      // console.log(userDetails.email)
    },

  getMechines() {
      axios.get('https://m4a.ramacsystems.com/backend/mechines/addGet.php')
        .then((res) => {
          this.machines = res.data
          this.compressionMolds = this.machines.filter(machine => machine.type == "Compression Mould");
         // console.log(this.machines)
        })
        .catch(function (error) {
            console.log(error)
        })
   },

 gotToDetails(machineName) {
     this.$router.push('/CompressionCharts/' + machineName)
   }
 } 
}
</script>

<style scoped>
.mechines,
h1,
td,
th {
    color: #fff;
}

h1 {
    float: left;
    margin-left: 10px;
    text-transform: uppercase;
    font-size: 20px;
}

#addMechine {
  float: right;
  margin-right: 90px;
}

i {
  margin: 0 10px;
}

button {
  margin: 10px;
}

.btn-info .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: darkgrey	;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 1px 0;
  margin-left: -70px;
  margin-top: 25px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.btn-info:hover .tooltiptext {
  visibility: visible;
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
}
</style>
