<template class="">
    <div class="login-page">
    <div class="alert" role="alert" id="snackbar">
      <strong>Error:</strong> Unable to logIn. Please check your Email/Password.
    </div>
    <div class="form bg-secondary">
        <form class="login-form" @submit.prevent="login">
        <input type="text" placeholder="Email" v-model="email">

        <input id="password" type="password" placeholder="Password" v-model="password"><span @click="showPswd"><i class="fa fa-eye field-icon"></i></span>
        <button type="button" class="btn-sm btn-info" @click="logIn">login</button>
        <p class="message">Forgot password? <a href="#" data-toggle="modal" data-target="#myModal">Reset</a></p>
        <p class="g-matrix">Powerd by <a href="http://g-matrixsystems.com/" target="_blank">G-Matrix Systems</a></p>
    </form>
    </div>

  <!-- PASS RESET MODAL -->
  <div class="modal fade" id="myModal" role="dialog">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Request password reset.</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
          <form class="pass_reset">
             <input 
             type="email" 
             placeholder="Enter email you are registered with." 
             id="email_pass_reset"
             class="email_pass_reset" 
             v-model="email_pass_reset" required>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-success" data-dismiss="modal" @click="pass_reset()">Submit</button>
          <button type="button" class="btn btn-sm btn-danger" data-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
// import router from '../../store/router'
import axios from 'axios'
export default {
  name: 'Login',
    data() {
      return {
        email: '',
        password: '',
        email_pass_reset: ''
      }
    },
    
    methods: {
    logIn() {
      this.$store.dispatch('retrieveToken', {
        email: this.email,
        password: this.password,
      })
    },

     showPswd() {
      var x = document.getElementById("password")
      if(x.type === "password") {
        x.type = "text"
      } else {
        x.type = "password"
      }
     },

     pass_reset() {
        let email_pass = document.getElementById('email_pass_reset').value
         if(email_pass == '') {
             alert();
         } else {
             let formData = new FormData();
             formData.append('email_pass_reset', this.email_pass_reset);
             axios({
                 url: 'https://m4a.ramacsystems.com/backend/users/logIn.php',
                 method: 'post',
                 data: formData,
                 config: { headers: { 'Content-Type' : 'multipart/formdata'}},
             })

             .then((res) => {
                 console.log(res.data);
             })
         }
     }
    }
}
</script>

<style scoped>
.login-page {
  width: 360px;
  padding: 8% 0 0;
  margin: auto;
  object-fit: fill;
  height: 100vh;
}

.form {
  position: relative;
  z-index: 1;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  color: #fff;
}
.form input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 10px;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 5px;
}

.form .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}
.form .message a {
  color: #4CAF50;
  text-decoration: none;
}

.form .g-matrix {
  margin-top: 40px;
  color: #fdeaea;
  font-size: 12px;
  margin-left: -170px;
  margin-bottom: -40px;
}
.form .g-matrix a {
  color: #7ff847;
  text-decoration: none;
}

.form .register-form {
  display: none;
}
.container {
  position: relative;
  z-index: 1;
  max-width: 300px;
  margin: 0 auto;
}
.container:before, .container:after {
  content: "";
  display: block;
  clear: both;
}
.container .info {
  margin: 50px auto;
  text-align: center;
}
.container .info h1 {
  margin: 0 0 15px;
  padding: 0;
  font-size: 36px;
  font-weight: 300;
  color: #1a1a1a;
}
.container .info span {
  color: #4d4d4d;
  font-size: 12px;
}
.container .info span a {
  color: #000000;
  text-decoration: none;
}
.container .info span .fa {
  color: #EF3B3A;
}

.field-icon {
  float: right;
  color: rgb(66, 71, 93);
  margin-left: -25px;
  margin-top: -42px;
  margin-right: 10px;
  position: relative;
  z-index: 2;
}
.field-icon:active,
.field-icon:hover {
  color: #1e283d;
}

.close {
  float: right;
}

.email_pass_reset {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 10px;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 5px;
}

.alert {
    width: 30%;
}

#snackbar {
	visibility: hidden;
	min-width: 250px;
  margin-left: -230px;
  text-align: center;
  background-color: #ff9966 !important;
	border-radius: 2px;
	padding: 5px;
	position: fixed;
	z-index: 1;
	left: 50%;
  top: 10px;
	font-size: 17px;
  }
  
  #snackbar.show {
	visibility: visible;
	-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
	animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }
</style>>