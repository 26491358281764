<template>
  <div id="app" class="bg-secondary">
  <div class="alert" role="alert" id="snackbar_home">
    WELCOME MANHOLE 4 AFRICA PRODUCTION MONITORING.
  </div>
  <LogIn v-if="!auth"></LogIn>
  <Nav v-if="auth"></Nav>
  </div>
</template>

<script>
import Nav from './components/NavNew.vue'
import LogIn from './components/auth/LogIn'
export default {
components: {
  Nav: Nav,
  LogIn: LogIn
},

computed: {
  auth() {
    return this.$store.getters.loggedIn
  }
}
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  object-fit: cover;
}

.alert {
    width: 30%;
}

#snackbar_home {
	visibility: hidden;
	min-width: 250px;
  margin-left: -230px;
  text-align: center;
  background-color: rgb(64, 206, 75) !important;
	border-radius: 2px;
	padding: 5px;
	position: fixed;
	z-index: 1;
	left: 50%;
  top: 10px;
	font-size: 17px;
  }
  
  #snackbar_home.show {
	visibility: visible;
	-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
	animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }

</style>
