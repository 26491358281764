<template>
    <div class="devive_comms">
            <!-- FILTER -->
            <form>
                <input id="startDate" type="date"/> <input id="time" type="time" step="2" name="appt-time"/>TO
                <input id="endDate" type="date"/> <input id="time1" type="time" step="2" name="appt-time"/> 
                <input id="limit" type="number" placeholder="Enter limit" style="max-width: 50px;">
            <button type="button" class="btn btn-info btn-sm" @click="getNewData()">GetData/Date</button>
            <router-link to="/DeviceList" class="btn btn-danger btn-sm" type="button" >Back</router-link>
            </form>
        <div class="mechines bg-secondary table-responsive">
            <table class="table table-hover table-dark" id="myTable" v>
            <thead>
                <tr>
                <th scope="col">DeviceID</th>
                <!-- <th scope="col" id="pulses">Pulses</th> -->
                <th scope="col">Digital<br>State</th>
                <th scope="col">FWVersion</th>
                <th scope="col">HWVersion</th>
                <th scope="col">Message<br>Count</th>
                <th scope="col">POST<br>Interval</th>
                <th scope="col">Packet<br>Type</th>
                <th scope="col">PulseCount</th>
                <th scope="col">curr_product</th>
                <th scope="col">deltaPulses</th>
                <th scope="col">differenceIn<br>Seconds</th>
                <th scope="col">WiFiRSSI</th>
                <th scope="col">WiFiSSID</th>
                <th scope="col">DateTime</th>
                </tr>
            </thead>
            <tbody>
                <tr 
                 v-for="message in messages" :key="message.SigfoxIDHex" >
                 
                <td style="text-transform: uppercase;">{{ message.DeviceId }}</td>

                <!-- <td class="pulses">{{ message.Counter1AccumalativeCount }}</td> -->
                <td>{{ message.DigitalState }}</td>
                <td>{{ message.FWVersion }}</td>
                <td>{{ message.HWVersion }}</td>
                <td>{{ message.MessageCount }}</td>
                <td>{{ message.POSTInterval }}</td>
                <td>{{ message.PacketType }}</td>
                <td>{{ message.PulseCount }}</td>
                <td>{{ message.curr_product }}</td>
                <td>{{ message.deltaPulses }}</td>
                <td>{{ message.differenceInSeconds }}</td>
                <td>{{ message.WiFiRSSI }}</td>
                <td>{{ message.WiFiSSID }}</td>
                <td>{{ new Date(message.DateTimeUTC + "Z").toLocaleString('en-GB') }}</td>
                </tr>
             </tbody>
            </table>
         </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data() {
        return {
            messages: []
        }
    },
mounted() {
    this.getData()
},

methods: {
  //API CALL AND DATA FORMART
    getData() {
      //CLEAR CHAT 
      if (this.ctx){
         this.chartData = [],
         this.dates = []
       }
      //FORMAT DATE FILTER
      var d = new Date();
      var endDate = new Date();
      endDate.setDate(endDate.getDate())
      let time = "00" + ":" + "00" + ":" + "00";
      let time1 = "23" + ":" + "59" + ":" + "00";
      document.getElementById('startDate').valueAsDate = d;
      document.getElementById('endDate').valueAsDate = endDate;
      document.querySelector('input[id="time"]').value = time;
      document.querySelector('input[id="time1"]').value = time1;
      this.to_date = new Date(document.getElementById("endDate").value + " " + document.getElementById('time1').value).toISOString();
      let DeviceId = this.$route.params.DeviceId
      this.from_date = new Date(document.getElementById('startDate').value + " "+ document.getElementById('time').value).toISOString();
      var limit = document.getElementById('limit').value = 15
       //API CALL
        axios.get('https://m4a.ramacsystems.com/backend/devices/getDeviceDetails.php?params=', {
          params: {
            DeviceId,
             from_date: this.from_date,
             to_date: this.to_date,
             limit: limit
          }
        })
        //RESPONSE AND RENDER CHART
        .then((response) => {
           this.messages = response.data

           console.log(this.messages)
       })
          .catch(error => console.log(error))
     },

     getNewData() {
       let to_date = new Date(document.getElementById("endDate").value + " " + document.getElementById('time1').value).toISOString();
       let DeviceId = this.$route.params.DeviceId
       let from_date = new Date(document.getElementById('startDate').value + " "+ document.getElementById('time').value).toDateString();
       var limit = document.getElementById('limit').value
        //API CALL
        axios.get('https://m4a.ramacsystems.com/backend/devices/getDeviceDetails.php?params=', {
          params: {
            DeviceId,
             from_date: from_date,
             to_date: to_date,
             limit: limit
          }
        })
      //RESPONSE AND RENDER CHART
      .then((response) => {
           this.messages = response.data
           console.log(this.messages)
       })
     },

    back() {
      this.$router.go(-1)
      }
    },

}
</script>
<style scoped>
form {
   float: right;
   margin-right: 30px;
   margin-bottom: 10px;
   }
input {
  border-radius: 7px;
  border: solid 1px lightblue;
}
.btn {
  margin-left: 10px;
}

.no_records {
    text-transform: uppercase;
    margin-top: 100px;
    text-align: center;
    color: #fff;
}

.mechines {
  overflow-y: auto;
  height: 800px;
}

.mechines table {
  border-collapse: collapse;
  width: 100% !important;
}

.mechines th,
.mechines td {
  padding: 8px 16px;
}

.mechines th {
  position: sticky;
  top: 0;
  background: #303a3a;
}

#maxRows {
  max-width: 100px;
  border-radius: 7px;
  border: solid 1px lightblue;
  margin-right: 550px;
  padding: 5px;
}
.pagination-container {
  margin-left: 10px;
}

</style>