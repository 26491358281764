<template>
  <div>
    <div class="form bg-secondary">
    <form class="login-form" @submit.prevent="">
        <input id="password1" type="password" placeholder="New password" v-model="password"><span @click="showPswd()"><i class="fa fa-eye field-icon"></i></span>
        <input id="confirm_password" type="password" placeholder="Confirm password" v-model="confirm_password"><span @click="showPswd()"></span>
        <button type="button" class="btn-sm btn-info" @click="Reset">Reset</button>
    </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            password: '',
            confirm_password: ''
        }
    },

    methods: {
      showPswd() {
      var x = document.getElementById("password1")
      if(x.type === "password") {
        x.type = "text"
      } else {
        x.type = "password"
      }        
      },
      
      async Reset() {
        const res = await axios.post('reset', {
          password: this.password,
          confirm_password: this.confirm_password,
          torken: this.$route.params.key
        })
        console.log(res)
      }
    }
}
</script>

<style>

</style>