<template>
    <div class="machine_config bg-dark">
     <div class="alert" role="alert" id="snackbar">
        <strong>Error:</strong> All fields are requred!!!
        </div>
       <div class="alert" role="alert" id="snackbar1">
        <strong>Success:</strong>Pulses/minute has been updated!!!
        </div>
        <form class="config">
          <div class="form-group">
            <label for="name">Mechine Name:</label>
            <input type="text" class="form-control" placeholder="" id="name" v-model="machine.machineName" disabled>
          </div>

          <div class="form-group">
            <label for="pulse">Pulse / meter:</label>
            <input type="text" class="form-control" placeholder="Enter pulses / minute" id="pulses" v-model="machine.pulseConfig">
          </div>

          <div class="form-group">
            <label for="threshold">Comm interval:</label>
            <input type="text" class="form-control" placeholder="Enter communication interval x2" id="comm_interval" :value="machine.comm_interval / 60">
          </div>

          <div class="form-group">
            <label for="threshold">Meters / minute:</label>
            <input type="text" class="form-control" placeholder="Enter threshold" id="threshold" v-model="machine.threshold">
          </div>
          
           <!-- MACHINE STATE IS NOW CHANGED FROM THE SUMMURY DB -->
          <!-- <div class="form-group">
            <label for="machine_state">Machine State:</label>
              <select class="form-control" v-model="machine.machine_state">
              <option v-for="state in states" :key="state.state">{{ state }}</option>
            </select>
          </div> -->

            <button type="button" class="btn-sm btn-info" @click="updateConfig(machine.ramacDevice)">Submit</button>
            <router-link tag="button" to="/Extruder" class="btn-sm btn-danger">Back</router-link>
        </form>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    props: {
        machine: {
            machineName: '',
            position: '',
            product: '',
            site: '',
            ramacDevice: '',
            machinesType: '',
            pulseConfig: '',
            threshold: '',
            comm_interval: '',
            machine_state: ''

        }
    },
    data() {
      return {
      states: [
              'On',
              'Off'
            ]
      }
    },

    computed: {
    commInterval() {
      return this.machine.comm_interval / 60;
    }
  },

    methods: {
        updateConfig(ramacDevice) {
            if (this.machine.pulseConfig === '') {
             var x = document.getElementById("snackbar");
                x.className = "show";
                setTimeout(function(){ 
                x.className = x.className.replace("show", "");
             }, 1000);
         } else {
              //AUDIT LOG PARAMS
            let fullname_user = localStorage.getItem('fullname') 
            let user_action = 'Edit Configuration'
            let description = fullname_user +" changed configurations setting for " + this.machine.machineName
            let pulseConfig = this.machine.pulseConfig
            let threshold = this.machine.threshold
            let state = this.machine.machine_state
            let comm_interval = document.getElementById('comm_interval').value * 60
            let product = "Default_Product"
            axios.get('https://m4a.ramacsystems.com/backend/mechines/editMachine.php?params', {
                params: {
                    ramacDevice,
                    pulseConfig,
                    threshold,
                    state,
                    comm_interval,
                    product,
                    fullname_user,
                    user_action,
                    description
                }
            })
            .then((res) => {
                console.log(res)
                var x = document.getElementById("snackbar1");
                x.className = "show";
                setTimeout(function(){ 
                x.className = x.className.replace("show", "");
             }, 500);

            setTimeout(() => {
             this.$router.go(-1)
            }, 500)
            })
          }
        }
    }
}
</script>

<style scoped>
.btn {
    margin-right: 10px;
}

input,
select {
    width: 50%;
    margin-left: 25%;
}

label {
  color: #bdb8d7;
}

button {
    margin: 10px;
}
.alert {
    width: 30%;
}

#snackbar {
	visibility: hidden;
	min-width: 250px;
    margin-left: -125px;
    text-align: center;
    background-color: #ff9966 !important;
	border-radius: 2px;
	padding: 5px;
	position: fixed;
	z-index: 1;
	left: 50%;
    top: 10px;
	font-size: 17px;
  }
  
  #snackbar.show {
	visibility: visible;
	-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
	animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }

#snackbar1 {
	visibility: hidden;
	min-width: 250px;
    margin-left: -125px;
    text-align: center;
    background-color: #5cff3b !important;
	border-radius: 2px;
	padding: 5px;
	position: fixed;
	z-index: 1;
	left: 50%;
    top: 10px;
	font-size: 17px;
  }
  
  #snackbar1.show {
	visibility: visible;
	-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
	animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }
</style>