<template>
   <div class="main_help bg-dark">
     <div class="main-content">
   <br>
   <!-- ABBREVIATIONS -->
    <div class="card" style="width: 69.5rem;">
      <div class="card-body">
        <h5 class="card-title">Abbreviations</h5>
        <h6 class="card-subtitle mb-2 text-muted">The following abbreviations are used in the document.</h6>
          <p class="card-text">M4A - Manhole 4 Africa</p>
          <p class="card-text">IOT - Internet Of Things</p>
          <p class="card-text">RAMAC - Remote Access management and Control Systems</p>
          <p class="card-text">DB - Database</p>
          <p class="card-text">TBC - To Be Continued </p>
          <p class="card-text">EG - Example given</p>
      </div>
    </div>
   <br>
   <!-- SYSTEM OVERVIEW-->
    <div class="card" style="width: 69.5rem;">
      <div class="card-body">
        <h5 class="card-title">System overview</h5>
        <h6 class="card-subtitle mb-2 text-muted">The m4a production consist of the following components.</h6>
          <p class="card-text">1. Lora Gateway – Gateway is used by the IOT devices installed on the machine to communicate with server.</p>
          <p class="card-text">2. RAMAC PC1 – RAMAC PC1 are devices installed on the machine to monitor production.</p>
          <p class="card-text">3. RAMAC - Remote Access management and Control Systems</p>
          <p class="card-text">4. Backend - Back End is for processing the data sent from the devices and store it in the DB.</p>
          <p class="card-text">5. MYSQL DB – MySQL DB is used to store processed data and raw data </p>
          <p class="card-text">6. Front End – Front End is the web portal which is described in this document.</p>

          <img class="m4a_img" src="../assets/img/systemoverview.png" alt="systemoverview">
          <h4>System Overview</h4>
      </div>
    </div>
   <br>
   <!-- SUMMARY DASHBOARD -->
    <div class="card" style="width: 69.5rem;">
      <div class="card-body">
        <h5 class="card-title">Summary Dashboard</h5>
        <h6 class="card-subtitle mb-2 text-muted">Summary dashboard shows the summary of the current production for all the machines. When you click a row for a specific machine it will navigate to the machine graph.</h6>
         <h5 class="sub-haerder">Extruders</h5> 
          <p class="card-text">The Extruders table it shows the summary of all the extruders. The RAMAC devices installed on all the extruder lines are configured to send data every 10 minutes as long the machine is on.</p>
          <p class="card-text">1.	Machine Status – This will change between (Running and Stopped), if the same number of pulses is received twice the status will show stopped and the row background will be yellow.</p>
          <p class="card-text">2.	Thresh – This is number of meters that the machine is suppose to produce per minute.</p>
          <p class="card-text">3.	Meters Per Minute – These are the meters the machine is producing per minute. If the meters per minute is lower that the number set as Thresh the row background will turn yellow.</p>
          <p class="card-text">4.	Sensor Status – This will change between (On and Off) if there is no data received for the past 15 minutes. </p>
         <h5 class="sub-haerder">Compression Moulds</h5> 
          <p class="card-text">The Compression moulds table shows the summary of all the compression moulds. The RAMAC devices installed on all the compression moulds are configured to send data every time when the machine opens or closing.</p>
          <p class="card-text">1.	Quantity Products – This shows the quantity of products for the current day.</p>
          <p class="card-text">2.	Sensor Status – This will change between (On and Off) if there is no state change in for more than the product manufacturing time set when adding product.</p>
         <h5 class="sub-haerder">Injection Moulds</h5> 
          <p class="card-text">The Injection moulds table shows the summary of all the compression moulds. The RAMAC devices installed on all the Injection moulds are configured to send data every time when the machine opens or closing.</p>
          <p class="card-text">1.	Quantity Products – This shows the quantity of products for the current day.</p>
          <p class="card-text">2.	Sensor Status – This will change between (On and Off) if there is no state change in for more than the product manufacturing time set when adding product.</p>

         <h5 class="sub-haerder">Summary Dashboard Config</h5> 
          <p class="card-text">The summary dashboard configuration allows you to change the machine status and the current product without navigating away from the dashboard.</p>
          <p class="card-text">1.	On the summary DB under the machine status column if you click on the machine status it gives you drop of all the machine status, select the status the machine is current on the machine will change to what you have selected.</p>
          <p class="card-text">2.	On the summary DB under the current product column if you click on the current product, it gives you a drop-down list of all the products select the current product in the machine current product will change to what you have selected.</p>
      </div>
          <img class="summarydb" src="../assets/img/summarydb.png">
          <router-link to="/" tag="h4">Summary Dashboard</router-link>
    </div>
    <br>
    <!-- CONFIGURATION -->
    <div class="card" style="width: 69.5rem;">
      <div class="card-body">
        <h5 class="card-title">Configuration</h5>
        <!-- EXTRUDER -->
        <h5 class="sub-haerder">Extruder</h5> 
        <h6 class="card-subtitle mb-2 text-muted">The extruder will display the list of all the extruder machines.</h6>
         <h5 class="sub-haerder">View (Eye Icon)</h5> 
          <p class="card-text">The View button will navigate to the machine page. On the machine summary page material extruded the default filter is for the current day and the machine history the default filter is for current date and previous seven day.</p>
          <p class="card-text">1.	Material Extruded Filter – This filter gives the ability to filter the graph you want to view for a selected data time and total meters produced at the selected date range. </p>
          <p class="card-text">2.	Machine History Filter – This filter gives the ability to filter the data for a specific date range or day.</p>
          <p class="card-text">3.	Export - This gives you the ability to export a report for a selected data range.</p>
         <h5 class="sub-haerder">Machine Summary</h5> 
          <p class="card-text">The machine summary shows the summary for the selected date range on the material extruded filter default is the current date.</p>
          <p class="card-text">1.	Machine state - This will change between (Running and Stopped), if the same number of pulses is received twice the status will show stopped.</p>
          <p class="card-text">2.	Sensor Status - This will change between (On and Off) if there is no data received for the past 15 minutes the status will be Off.</p>
          <p class="card-text">3.	Total Meters – These are total meters that have been produce on the selected date range.</p>
         <h5 class="sub-haerder">Machine configuration (Gear icon)</h5> 
          <p class="card-text">The config button will navigate to configuration page on the configuration page you can add new or update the existing configurations.</p>
          <p class="card-text">1.	Pulse Per Meter – These are the pulses generated by the encoder per meter the machine produced.</p>
          <p class="card-text">2.	Comm Interval – This is the communication interval that the RAMAC device must send a message when the machine is running.</p>
          <p class="card-text">3.	Meter Per Minute – These are the meters that the machine is expected to produce per minute. Meters per mite is used as a thresh hold on the summary dashboard.</p>
          <p class="card-text">4.	Machine State – The state of the machine.</p>
         <h5 class="sub-haerder">Machine Edit (Pen Icon)</h5> 
          <p class="card-text">This button navigates to the edit page.</p>
          <p class="card-text">1.	Machine Name – The name of the machine.</p>
          <p class="card-text">2.	Position – The line number of the machine.</p>
          <p class="card-text">3.	Type – The type of the machine.</p>
          <p class="card-text">4.	Site – The site where the machine is installed.</p>

        <!-- COMPRESSION AND INJECTION CONFIGURATION -->
        <h5 class="sub-haerder">Compression and Injection Configuration</h5> 
        <h6 class="card-subtitle mb-2 text-muted">The compression will display the list of all the compression moulds and injection will display all the injection moulds.</h6>
         <h5 class="sub-haerder">View (Eye Icon)</h5> 
          <p class="card-text">The View button will navigate to the machine page. On the machine page compress monitoring the default filter is for the current day and the machine history the default filter is for current date and previous seven day.</p>
          <p class="card-text">1.	Compress Monitoring Filter - This filter gives the ability to filter the graph you want to view for a selected data time and total meters produced at the selected date range.</p>
          <p class="card-text">2.	Machine History Filter – This filter gives the ability to filter the data for a specific date range or day.</p>
          <p class="card-text">3.	Export -- This gives you the ability to export a report for a selected data range.</p>
         <h5 class="sub-haerder">Machine Summary</h5> 
          <p class="card-text">The machine summary shows the summary for the selected date range on the compress monitoring filter default is the current data.</p>
          <p class="card-text">1.	Machine state - This will change between (On and Off), if the last datetime for the last data that was received is more than manufacturing time of current product in the machine the status will show Off.</p>
          <p class="card-text">2.	Total Products – These are total products that have been produced on the selected date range.</p>

         <h5 class="sub-haerder">Machine configuration (Gear icon)</h5> 
          <p class="card-text">The config button will navigate to configuration page on the configure page you add new or update the existing configurations.</p>
          <p class="card-text">1.	Product – The product that is current in the production line.</p>
          <p class="card-text">2.	Machine State – The state of the machine.</p>

         <h5 class="sub-haerder">Machine Edit (Pen Icon)</h5> 
          <p class="card-text">This button navigates to the edit page.</p>
          <p class="card-text">1.	Machine Name – The name of the machine.</p>
          <p class="card-text">2.	Position – The line number of the machine.</p>
          <p class="card-text">3.	Type – The type of the machine.</p>
          <p class="card-text">4.	Site – The site where the machine is installed.</p>
      </div>
       <img class="summarydb" src="../assets/img/confgExtruder.png">
       <router-link tag="h4" to="/Extruder">
         Extruder Config
       </router-link>
       <br>
       <img class="summarydb" src="../assets/img/extrudergraph.png">
       <br>
       <img class="summarydb" src="../assets/img/confgCompress.png">
       <router-link tag="h4" to="/Compression">
         Compression Config
       </router-link>
       <br>
       <img class="summarydb" src="../assets/img/injectionconfig.png">
       <router-link tag="h4" to="/Injection">
         Injection Config
       </router-link>
       <br>
       <img class="summarydb" src="../assets/img/compressgraph.png">
    </div>

    <br>
    <!-- PRODUCTS -->
    <div class="card" style="width: 69.5rem;">
      <div class="card-body">
        <h5 class="card-title">Products</h5>
        <h6 class="card-subtitle mb-2 text-muted">The product page shows the list of all the existing products. On the product page you can add or update the existing products.</h6>
         <h5 class="sub-haerder">Add / Edit Product.</h5> 
          <p class="card-text">Required fields to fill in when adding or editing products.</p>
          <p class="card-text">1.	Name – Product name.</p>
          <p class="card-text">2.	Type – Type of a product. e.g. (Lid, Base, etc)</p>
          <p class="card-text">3.	MNF Time – The time the machine is going to take to complete the product.</p>
          <p class="card-text">4.	Description – Details about the Product.</p>
         <h5 class="sub-haerder">Delete Product</h5> 
      </div>
        <img class="summarydb" src="../assets/img/products.png">

        <router-link tag="h4" to="/Products">
          Products
        </router-link>
        <br>
        <img class="summarydb" src="../assets/img/addproduct.png">
    </div>
    <br>

    <!-- MACHINE STATUS-->
        <div class="card" style="width: 69.5rem;">
      <div class="card-body">
        <h5 class="card-title">Machine Status</h5>
        <h6 class="card-subtitle mb-2 text-muted">The machine status shows the list off all machine status and allows you to add new machine status.</h6>
         <h5 class="sub-haerder">Add Machine Status.</h5> 
          <p class="card-text">Required fields to fill in when adding or editing machine status.</p>
          <p class="card-text">1.	Name – Name that will be displayed on the summury DB.</p>
          <p class="card-text">2.	Description – This is the full discription about the machine status that you are adding.</p>
      </div>
        <img class="summarydb" src="../assets/img/machinestatus.png">

        <router-link tag="h4" to="/MachineStatus">
            Machine Status
          </router-link>
          <br>
      </div>
      <br>
    <!-- USERS -->
    <div class="card" style="width: 69.5rem;">
      <div class="card-body">
        <h5 class="card-title">Users</h5>
        <h6 class="card-subtitle mb-2 text-muted">The user section will show the list of all the user that can long into the system. On this section user will have the ability to add / delete and view user details.</h6>
         <h5 class="sub-haerder">User Types</h5> 
          <p class="card-text">User type will have different access.</p>
          <p class="card-text">1.	System Admin - Developers.</p>
          <p class="card-text">2.	Administrator - Full access to the portal.</p>
          <p class="card-text">3.	Manager - Limited access will not be allowed to add more user.</p>
         <h5 class="sub-haerder">Add / Edit User</h5>
         <h6 class="card-subtitle mb-2 text-muted">Required fields when adding or editing a user.</h6>
         <p class="card-text">User type will have different access.</p>
         <p class="card-text">1.	Name – Username’s full name.</p>
         <p class="card-text">2.	Email – Valid email address.</p>
         <p class="card-text">3.	User Type – </p>
         <p class="card-text">4.	Phone – Cell / Work phone number.</p>
         <p class="card-text">5.	Telegram – Telegram username.</p>
         <p class="card-text">6.	Password – Default user password.</p>
      </div>
      <img class="summarydb" src="../assets/img/users.png">
      <router-link tag="h4" to="/Users">
        Users
      </router-link>
    </div>
    <br>
   </div>
   </div>
</template>

<script>
export default {
   data() {
      return {

      }
   }
}
</script>

<style scoped>
.main_help
{
  max-height: 90vh;
  width: 100%;
  overflow-y: scroll;
}
.main-content {
  margin-left: 19%;
}
.card-title 
{
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
}

.card-text 
{
  text-align: left;
  margin-left: 30px;
  padding-bottom: -10px;
}

.card-subtitle 
{
  text-align: left;
  margin-left: 15px;
}

.sub-haerder 
{
  text-align: left;
  margin-left: 15px;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
}

img {
  margin: 10px 10px 10px 10px;
  border-radius: 5px;
}

h4 {
  font-style: italic;
  font-size: 20px;
  cursor: pointer;
  float: left;
}

h4:hover {
  color: rgb(189, 189, 189);
}
</style>