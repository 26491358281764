import Vue from 'vue'
import Vuex from 'vuex'
//import users from './users'
import auth from './auth.js'
import sammury_db from './machines/sammury_db.js'
import machines from './machines/machines'
import products from './/machines/products.js'
Vue.use(Vuex)
export default new Vuex.Store({
    modules: {
        auth,
        sammury_db,
        machines,
        products
    }
})