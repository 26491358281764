<template>
    <div class="addProduct  bg-dark">
         <div class="alert" role="alert" id="snackbar">
            <strong>Error:</strong> All fields are requred!!!
        </div>
         <div class="alert" role="alert" id="snackbar1">
            <strong>Sucess:</strong> New product added.
        </div>
         <div class="alert" role="alert" id="snackbar2">
            <strong>Error:</strong> Product already exist.
        </div>
        <form class="add-form">
         <div class="form-group">
            <label for="name">Name:</label>
            <input type="text" class="form-control" placeholder="Enter name" id="name" v-model="name">
        </div>

         <div class="form-group">
            <label for="machine_type">Machine Type:</label>
            <select class="form-control" v-model="machine_type" id="machine_type">
                 <option disabled selected> {{ machine_type }}</option>
                 <option v-for="types in machines" :key="types.types">{{ types }}</option>
            </select>
        </div>

         <div class="form-group">
            <label for="type">Type:</label>
            <input type="text" class="form-control" placeholder="Enter type" id="type" v-model="type">
        </div>
         <div class="form-group">
            <label for="mnf_time">MNF Time:</label>
            <input type="number" class="form-control" placeholder="Enter MNF Time" id="mnf_time" v-model="mnf_time">
        </div>
         <div class="form-group">
            <label for="description">Description:</label>
            <input type="text" class="form-control" placeholder="Enter description" id="description" v-model="description">
        </div>
      <button type="button" class="btn-sm btn-info" @click="addProduct()">Submit</button>
      <router-link tag="button" to="/Products" class="btn-sm btn-danger">Cancel</router-link>
    </form>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            name: '',
            machine_type: 'Choose machine type',
            type: '',
            mnf_time: '',
            description: '',
            function_add: 'addProduct',
            machines: [
                'Compression Mould',
                'Injection Mould',
                'Extruder',
                'Corrugator'
            ],
        }
    },

    methods: {
        //ADD PRODUCTS 
        addProduct() {
            //VALIDATE FORM INPUTS
            if(this.name == '' || this.type == '' || this.mnf_time == '' || this.description == '') {
               var x = document.getElementById("snackbar");
                x.className = "show";
                setTimeout(function(){ 
                x.className = x.className.replace("show", "");
             }, 2000);
            }else {
            let formData = new FormData
            console.log(formData)
            //AUDIT LOG PARAMS
             let fullname_user = localStorage.getItem('fullname') 
             let user_action = 'Add product'
             let l_description = fullname_user +" added new product " + this.name
            formData.append('name', this.name)
            formData.append('machine_type', this.machine_type)
            formData.append('type', this.type)
            formData.append('mnf_time', this.mnf_time)
            formData.append('description', this.description)
            formData.append('function', this.function_add)
            formData.append('fullname_user', fullname_user);
            formData.append('user_action', user_action);
            formData.append('l_description', l_description);
            axios({
                url: 'https://m4a.ramacsystems.com/backend/products/products.php',
                //url: 'http://m4abackend/products/products.php',
                method: 'post',
                data: formData,
                config: { headers: {'Content-Type': 'multipart/formdata'}}
            })
            .then((res) => {
                console.log(res)
                //PRODUCT ADDED
                var x = document.getElementById("snackbar1");
                    x.className = "show";
                    setTimeout(function(){ 
                    x.className = x.className.replace("show", "");
                }, 2000);

                //CLEAR FORM
                this.name = '',
                this.type = '',
                this.mnf_time = '',
                this.description = ''
            })
        .catch(function (error) {
            console.log(error)
            var x = document.getElementById("snackbar2");
                x.className = "show";
                setTimeout(function(){ 
                x.className = x.className.replace("show", "");
             }, 2000);
        })
        }
      }
    }


}
</script>

<style scoped>
.btn {
    margin-right: 10px;
}

input {
    width: 50%;
    margin-left: 25%;
}


select {
    width: 50%;
    margin-left: 25%;
}

label {
    color: #fff;
}

button {
    margin: 10px;
}

.alert {
    width: 30%;
}

#snackbar {
	visibility: hidden;
	min-width: 250px;
    margin-left: -125px;
    text-align: center;
    background-color: #ff9966 !important;
	border-radius: 2px;
	padding: 5px;
	position: fixed;
	z-index: 1;
	left: 50%;
    top: 10px;
	font-size: 17px;
  }
  
  #snackbar.show {
	visibility: visible;
	-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
	animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }

#snackbar1 {
	visibility: hidden;
	min-width: 250px;
    margin-left: -125px;
    text-align: center;
    background-color: #14eb00 !important;
	border-radius: 2px;
	padding: 5px;
	position: fixed;
	z-index: 1;
	left: 50%;
    top: 10px;
	font-size: 17px;
  }
  
  #snackbar1.show {
	visibility: visible;
	-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
	animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }

#snackbar2 {
	visibility: hidden;
	min-width: 250px;
    margin-left: -125px;
    text-align: center;
    background-color: #ff9966 !important;
	border-radius: 2px;
	padding: 5px;
	position: fixed;
	z-index: 1;
	left: 50%;
    top: 10px;
	font-size: 17px;
  }
  
  #snackbar2.show {
	visibility: visible;
	-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
	animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }
</style>