<template>
  <div class="bg-secondary main_summury">
        <div class="flex-container bg-dark heading"> 
         <div class="heading_header">
           <h1 class="">SUMMARY DASHBOARD 
              <span class="">
                    <select
                    :disabled="disabled"
                    class="form-control bg-secondary" 
                    v-model="current_config" 
                    @change="system_conf()" >
                    <option disabled selected>{{ old_config }}</option>
                    <option>RUNNING</option>
                    <option>POWER CUT</option>
                    <option>WEEKEND</option>
                  </select>
              </span>
           </h1> 
        </div>
      <!-- GATE WAYS  -->
      <!-- <div class="gate_ways">
          <div class="">
              <gate-ways></gate-ways>
          </div>
        </div> -->
    </div>

    <div class="flex-container bg-dark"> 
      <!-- COMPRESSION MOLDS  -->
        <div class="compressionMolds">
          <div class="compress_heading">
            <h4>EXTRUDERS</h4>
          </div>
          <div class="compress_cotents">
              <extruder></extruder>
          </div>
        </div>
        <!-- INJECTION MOLD -->
        <div class="injectionMolds">
          <div class="Injection_heading">
            <h4>COMPRESSION MOULDS</h4>
          </div>
          <div class="Injection_cotents">
               <CompressionMold></CompressionMold>
          </div>
        </div>
    </div>
    <!-- COMPRESSION MOLDS -->
    <div class="flex-container bg-dark"> 
        <div class="extruder">
         <div class="extruder_heading">
            <h4>INJECTION MOULDS</h4>
          </div>
          <div class="extruder_cotents">
            <Injection-mold></Injection-mold>
          </div>
        </div>

        <div class="corrugator">
         <div class="corrugator_heading">
            <h4>CORRUGATORS</h4>
          </div>
          <div class="corrugator_cotents">
            <Corrugator></Corrugator>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import CompressionMold from './dashboard/mainDbFiles/CompressionMold.vue'
import InjectionMold from './dashboard/mainDbFiles/InjectionMold.vue'
import Extruder from './dashboard/mainDbFiles/Extruder.vue'
import Corrugator from './dashboard/mainDbFiles/Corrugator.vue'
// import GateWays from './gate_ways/GateWays.vue'
import axios from 'axios'
export default {
  data() {
    return {
      machines: [],
      current_config: '',
      old_config: '',
      userType: '',
      disabled: false
    }
  },
  components: {
    CompressionMold,
    InjectionMold,
    Corrugator,
    Extruder,
    // GateWays
  },
  
mounted() {
  this.getSystem();
  this.getUserDetails()
    if(this.userType == 'viewer')
  {
    this.disabled = true
  }
},

compressionMoldss() {
  return this.$store.state
},

beforeMount() {
  this.$store.dispatch('fetchmachines')
  this.$store.dispatch('fetchAllProducts')
},

created() {
  setInterval(() => {
   this.$store.dispatch('fetchmachines')
  }, 120000)
},

  methods: {
    getSystem() {
    axios.get('https://m4a.ramacsystems.com/backend/system_conf/system_conf.php')
      .then((res) => {
        this.current_config = res.data[0].system_config
      })
    },

    system_conf() {
      let formData = new FormData
      var action = 'update_system'
      //USER DETAILS
      let fullname = localStorage.getItem('fullname') 
      let description = fullname +" changed systems status to " + this.current_config
      
      formData.append('action', action);
      formData.append('fullname', fullname)
      formData.append('description', description)
      formData.append('current_config', this.current_config)
      formData.append('action', action)
      axios({
        url: 'https://m4a.ramacsystems.com/backend/system_conf/system_conf.php',
        method: 'post',
        data: formData,
        config: { headers: { 'Content-Type' : 'multipart/formdata'}}
      })
      .then(() => {        
      })
      .catch((error)=> {
        console.log(error)
      })
    },
    //GET USER DETAILS
  getUserDetails() {
    this.userType = localStorage.getItem('userType')
  },
    
    //DEBUG NOTIFICATION TEST
    notifications() {},

    //MODAL FUNCTIONS
    openModal(position) {
     document.getElementById('myModal').style.display = 'block'
     this.machine_name = document.getElementById('machine_name').value = position
    },
    closeModal() {
     document.getElementById('myModal').style.display = 'none'
    },
  }
}
</script>

<style scoped>
.main_summury {
  object-fit: fill;
  height: 85vh;
}

form {
   float: right;
   }

input {
  border-radius: 7px;
  border: solid 1px lightblue;
}
.btn {
  margin-left: 10px;
}
h1 {
   float: left;
   margin-left: 10px;
   color:  #bdb8d7;
   text-transform: uppercase;
   font-size: 20px;
}

h4 {
  color: #bdb8d7;
}
/*CONTAINTER STYLE */
.flex-container {
  display: flex;
  margin-top: 5px;
  width: 100%;
}

.flex-container > div {
  margin: 10px;
  padding: 20px;
  width: 50%;
}
.heading {
  height: 80px;
  width: 100%;
}

/* .gate_ways {
  margin-left: 40% !important;
  overflow: hidden;
} */

.heading_header {
  overflow: hidden !important;
  width: 70% !important;
  /* position: fixed; */
  text-transform: uppercase;
  font-size: 300px !important;
}

.heading_header,
h1 {
  margin-top: -20px;
  text-transform: uppercase;
  font-size: 25px !important;
}

@media only screen and (max-width: 5184) {
  #app {
    width: 100%;
  }
}

.fa:hover {
  color: #fff;
}

#myModal1 {
  display: none;
}

#myModal1 {
  display: none; 
  position: fixed; 
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%;
  overflow: auto; 
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.4); 
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; 
  padding: 20px;
  border: 1px solid #888;
  width: 80%; 
}

select {
  width: 40%;
  height: 27px;
  font-size: 10px;
  color: #fff;
}
</style>