import axios from 'axios'

const state = { 
    machines: [],
    extruders: [],
    compressionMolds: [],
    injectionMoulds: [],
    corrugator: []
};

const getters = { 
    machinesList: state => state.machines
};

const actions = { 
    async fetchmachines({commit}){
       const res = await axios.get('https://m4a.ramacsystems.com/backend/mechines/addGet.php')
       var allMachines = res.data
        commit("setMachines", res.data)        
        commit('setExtruder', allMachines.filter(machine => machine.type == "Extruder"))
        commit('setCompressionMolds', allMachines.filter(machine => machine.type == "Compression Mould"))
        commit('setInjectionMoulds', allMachines.filter(machine => machine.type == "Injection Mould"))
        commit('setCorrugator', allMachines.filter(machine => machine.type == "Corrugator"))
        // console.log(this.state.machines.injectionMoulds)
    },
};
const mutations = { 
    setMachines: (state, machines) => (
        state.machines = machines
    ),

    setExtruder: (state, extruders) => (
        state.extruders = extruders
    ),
    setCompressionMolds: (state, compressionMolds) => (
        state.compressionMolds = compressionMolds
    ),
    setInjectionMoulds: (state, injectionMoulds) => (
        state.injectionMoulds = injectionMoulds
    ),
    setCorrugator: (state, corrugator) => (
        state.corrugator = corrugator
    )
};

export default {
    state,
    getters,
    actions,
    mutations
}