<template>
  <div class="mechines bg-secondary">
  <h1 class="">Main Factory Machines</h1>
  <router-link to="/AddMechine" class="btn btn-info btn-sm" id="addMechine"  v-if="userType == 'Super Admin' || userType  == 'Admin'">Add Machine</router-link>
      <table class="table table-hover table-dark">
      <thead>
        <tr>
          <th scope="col">Machine</th>
          <th scope="col">RamacDevice</th>
          <th scope="col">LastSeen</th>
          <th scope="col">Pulse/Minute</th>
          <th scope="col">Comm Interval <span>Minutes</span></th>
          <th scope="col">State</th>
          <th scope="col"  v-if="userType == 'Super Admin' || userType  == 'Admin' || userType  == 'Manager'">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="machine in extruders" :key="machine.id"  id="machine_row"  class="machine_status" :class="machine.status">
          <td class="machine_name">{{ machine.machineName }}</td>
          <td class="device_id">{{ machine.ramacDevice }}</td>
          <td>{{ machine.updatedDate }}</td>
          <td>{{ machine.pulseConfig }}</td>
          <td>{{ (machine.comm_interval / 60) }}</td>
          <td>{{ machine.machine_state }}</td>
          <td>
            <i class="fa fa-eye btn btn-info"  v-if="userType == 'Super Admin' || userType  == 'Admin'" @click="gotToDetails(machine.machineName)"><span class="tooltiptext">View machine details</span></i>
            <router-link  
            v-if="userType == 'Super Admin' || userType  == 'Admin' || userType  == 'Manager'" 
            tag="i" 
            :to="{ name: 'ExtruderConfig', params: { machine }}" class="btn btn-info fa fa-cog">
              <span class="tooltiptext">Machine configuration</span>
              </router-link>
            <router-link 
             v-if="userType == 'Super Admin' || userType  == 'Admin'"
            tag="i" 
            :to="{ name: 'EditExtruder', params: { machine }}" 
            class="btn btn-info fa fa-pen">
              <span class="tooltiptext">Edit machine details</span>
              </router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import axios from 'axios'
export default {
props: ['machine'],
data() {
  return {
    mechines: [],
    extruders: [],
    userType: ''
  }
},

mounted () {
  this.getMechines();
  this.getUserDetails();
},

methods: {
  getMechines() {
      axios.get('https://m4a.ramacsystems.com/backend/mechines/addGet.php')
        .then((res) => {
          this.mechines = res.data
          this.extruders = this.mechines.filter(machine => machine.type == "Corrugator")
        })
        .catch(function (error) {
            console.log(error)
        })
   },
  //GET USER DETAILS
    getUserDetails() {
      let userDetails = localStorage.getItem('userType')
      this.userType = userDetails
      // console.log(userDetails)
      // console.log(userDetails.email)
    },
     //GO TO CHARTS
 gotToDetails(machineName) {
     this.$router.push('/ExtruderCharts/' + machineName)
    //  console.log(machineName)
   },

    toggleModal(machine) {
      ('toggleModal', machine);
    },
  //GO TO CONFIG   
  config(machineName) {
    this.$router.push('/MachineConfig/' + machineName)
  }  
 } 
}
</script>

<style scoped>
.mechines,
h1,
td,
th {
    color: #fff;
}

h1 {
    float: left;
    margin-left: 10px;
    text-transform: uppercase;
    font-size: 20px;
}

#addMechine {
  float: right;
  margin-right: 90px;
}

i {
  margin: 0 10px;
}
.modal-body,
.modal-title {
  color: #000;
}

.close {
  float: right;
}

.btn-info {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.btn-info .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: darkgrey	;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 1px 0;
  margin-left: -70px;
  margin-top: 25px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.btn-info:hover .tooltiptext {
  visibility: visible;
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
}

.device_id {
  text-transform: uppercase;
}

/* .badge {
  background-color: #64bbf5;
  margin-top: -19px;
  margin-left: 5px;
}

.badge:hover,
.badge:active {
  background-color: #D5DBDB;
} */

.machine_name {
  word-wrap: break-word;
  width: 220px;
}

span {
  font-size: 10px;
  font-family: cursive;
  font-weight: lighter;
}

 /* @media only screen and (max-width: 2000px) {
.btn-info .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: darkgrey	;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 1px 0;
  margin-left: -50px;
  margin-top: 25px;

  Position: tooltip;
  position: absolute;
  z-index: 1;
}

.btn-info:hover .tooltiptext {
  visibility: visible;
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
}
}  */
</style>
