<template>
  <div class="mechines bg-secondary">
  <h1 class="">MACHINE STATUS</h1>
  <router-link to="/AddMachineStatus" class="btn-sm btn-info" id="addUser" v-if="userType == 'Super Admin' || userType  == 'Admin' || userType  == 'Manager'">Add Status</router-link>
  <table class="table table-hover table-dark">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Name</th>
      <th scope="col">Description</th>
      <th scope="col">Created</th>
      <th scope="col" v-if="userType == 'Super Admin' || userType  == 'Admin'">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="(status, index) in machine_status" :key="status.id">
      <td>{{ index+1 }}</td>
      <td>{{ status.name }}</td>
      <td>{{ status.description }}</td>
      <td>{{ status.date_added }}</td>
      <td>
        <router-link 
        v-if="userType == 'Super Admin' || userType  == 'Admin' || userType  == 'Manager'"
        tag="i"
        :to="{ name: 'EditMachineStatus', params: { status }}"
        class="btn btn-info btn-sm">
        <i class="fa fa-pen"></i>
        </router-link>
        <button 
        v-if="userType == 'Super Admin' || userType  == 'Admin'"
        @click="deletestatus(status)"
        class="btn btn-info btn-sm">
        <i class="fa fa-trash-alt"></i>
        </button>
      </td>
    </tr>
  </tbody>
</table>
  </div>
</template>
<script>
import axios from 'axios'
export default {
    data () {
      return {
        machine_status: [],
        userType: ''
      }
    },
    
  mounted() {
   this.allstatus()
   this.userType = localStorage.getItem('userType')
  },

  methods: {
    //SELECT ALL statusS
    allstatus() {
      axios.get('https://m4a.ramacsystems.com/backend/mechines/machine_config/machine_config.php')
     // axios.get('http://m4abackend/mechines/machine_config/machine_config.php')
        .then((res) => {
          this.machine_status = res.data
        })
        .catch(function (error) {
            console.log(error)
        })
    },

    //DELETE USER
    deletestatus(status) {
        var r = confirm("Are you sure you want to delete this statuss ?");
        if (r == true) {
        let formData = new FormData 

        let fullname = localStorage.getItem('fullname') 
        let action = 'DeleteStatus'
        let description = fullname +" deleted status " + status.name  
        let id = status.id

        formData.append('fullname', fullname)
        formData.append('action', action)
        formData.append('description', description)
        formData.append('id', id)

        axios('https://m4a.ramacsystems.com/backend/mechines/machine_config/machine_config.php', {
          method: 'post',
          data: formData,
          config: { headers: { 'Content-Type' : 'multipart/formdata'}},
        })
        .then(() => {
          this.allstatus()
        })
     } else {
       return
        }
    },
  },
}
</script>
<style scoped>
.mechines,
h1,
td,
th {
    color: rgb(255, 255, 255);
}

h1 {
    float: left;
    margin-left: 10px;
    text-transform: uppercase;
    font-size: 20px;
}

#addUser {
  float: right;
  margin-right: 90px;
}

.btn {
  margin: -10px 10px -10px 0px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #5bc0de;
}

input:focus + .slider {
  box-shadow: 0 0 1px #5bc0de;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
