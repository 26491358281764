import axios from 'axios'
axios.defaults.baseURL = 'https://m4a.ramacsystems.com/backend/users'

export default {
    state: {
        user: null,
        token: localStorage.getItem('access_token'),
        userDetails: localStorage.getItem('userDetails')
    },

    mutations: {
        retrieveToken(state, token) {
            state.token = token
          },

          destroyToken(state) {
            state.token = null
          },

          storeUser(state, userDetails) {
            state.userDetails = userDetails
        },
    },
    
    actions: {
        retrieveToken(context, credentials) {
        return new Promise((resolve, reject) => {
            axios.get('/logIn.php', {
                params: {
                    email: credentials.email,
                    password: credentials.password
                }
            })
            .then(response => {
                context.commit('storeUser', response.data) 
                const token = response.data.access_token[0]
                const dateTorken = new Date()
                const torken_active = dateTorken.getTime()
                context.commit('retrieveToken', token)
                resolve(response)
                //USER DETAILS
                let userData = response.data.user_data[0];
                let userDetails = JSON.parse(userData)
                let userType = userDetails.userType
                let fullname = userDetails.fullname
                // console.log(userType)
                localStorage.setItem('userType', userType)
                localStorage.setItem('fullname', fullname)

                if (userType != 'viewer')
                {
                    localStorage.setItem('dateTorken', torken_active + 43200000)
                    localStorage.setItem('access_token', token)
                }
            })

            .catch(error => {
                // console.log(error)
                // reject(error)
                var x = document.getElementById("snackbar");
                x.className = "show";
                setTimeout(function(){ 
                x.className = x.className.replace("show", "");
             }, 3000);
            })
            })
        },

        destroyToken(context) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
            if (context.getters.loggedIn) {
              return new Promise(() => {
                    localStorage.removeItem('access_token')
                    localStorage.removeItem('userType')
                    localStorage.removeItem('fullname')
                    localStorage.removeItem('dateTorken')
                    context.commit('destroyToken')
              })
            }
          },
    },

    getters: {
        loggedIn(state) {
            const userType = localStorage.getItem('userType')
            if(userType != 'viewer'){
              return state.token !== null
            } else if(userType == 'viewer') {
                return true
            }
          }
    }
}