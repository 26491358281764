<template>
  <div class="pieChart">
         <table class="table table-hover table-dark">
            <thead>
              <tr>
                <th scope="col">Machine</th>
                <th scope="col">Machine<br>State</th>
                <th scope="col">Current<br>Product</th>
                <th scope="col">Quantity<br>Products</th>
                <th scope="col">Sensor<br>Status</th>
                <th scope="col" v-if="userType != 'viewer'">View</th>
              </tr>
            </thead>
            <tbody>
              <tr 
               v-for="(machine) in injectionMouldss" 
              :key="machine.id"  id="machine_row" 
              :class="statusFomart(machine)">
                <td>{{ machine.machineName }}</td>
                <td>
                  <select 
                    :disabled="disabled"
                    :class="statusFomart(machine)"
                    v-model="machine.machine_state" 
                    @change="machine_config(machine)" >
                    <option disabled selected>{{ machine.machine_state  }}</option>
                    <option v-for="status in machine_status" :key="status.id">{{ status.name }}</option>
                  </select>
                </td>
                <td>
                  <select 
                    :disabled="disabled"
                    :class="statusFomart(machine)"
                    v-model="machine.product" 
                    @change="product_config(machine)" >
                    <option disabled selected>{{ machine.product  }}</option>
                    <option v-for="product in products" :key="product.id">{{ product.name }}</option>
                  </select>
                </td>
                <td v-bind="totalproducts(machine)">{{ machine.deltaPulses }}</td>
                <td style="margin-left: -10px;" v-if="new Date().getTime() / 60000 - new Date(`${machine.updatedDate} Z`).getTime() / 60000 < machine.comm_interval">On</td>
                <td style="margin-left: -10px;" v-else>Off</td>
                <td><i class="fa fa-eye" @click="injectionChart(machine.machineName)" v-if="userType != 'viewer'"></i></td>
              </tr>
            </tbody>
          </table>
      </div>
</template>
<script>
import axios from 'axios'
export default {
data() {
  return {
    machines: [],
    injectionMoulds: [],
    QtyPerDay: '',
    status: '',
    mstatus: 'Select New Status',
    machine_status: [],
    machine_name: '',
    products: [],
    userType: '',
    disabled: false,
    TotalProduct: ''
  }
},

mounted () {
  this.allstatus();
  this.getCompressionProducts()
  this.userType = localStorage.getItem('userType')
  if(this.userType == 'viewer')
  {
    this.disabled = true
  }
},

computed: {
  injectionMouldss() {
    return this.$store.state.machines.injectionMoulds
  }
},

methods: {
  getCompressionProducts() {
    axios.get('https://m4a.ramacsystems.com/backend/products/getDiffProducts.php', 
    //axios.get('http://m4abackend/products/getDiffProducts.php', 
    {
      params: {
        action: 'getInjectionProducts'
      }
    })
    .then((res) => {
      this.products = res.data
    })
    .catch((error) => {
      console.log(error)
    })
  },

 gotToDetails(machineName) {
     this.$router.push('/CompressionCharts/' + machineName)
   },

  totalproducts(machine) {
  axios.get('https://m4a.ramacsystems.com/backend/mechines/addGet.php?params', {
    params: {
      action: 'totalproducts',
      DeviceId: machine.ramacDevice,
      PacketType: machine.PacketType
    }
  })
  .then((res) => {
    machine.deltaPulses = (res.data[0].TotalProducts / 1).toFixed(0)
    // console.log(machine.deltaPulses)
  })
},
//GET USER DETAILS
getUserDetails() {
  let userDetails = JSON.parse(localStorage.getItem('userDetails'))

  this.userType = userDetails.userType
  console.log(userDetails.userType)
  console.log(userDetails.email)
},

statusFomart(machine) {
          let oldDateTime = new Date(machine.updatedDate + 'Z');
          let currentTime = new Date();
          currentTime = currentTime.getTime();
          oldDateTime = oldDateTime.getTime();
          let On;
          let machineOn;
          let Of

          if((currentTime / 1000) - (oldDateTime / 1000) < machine.comm_interval) 
          {
            On = true
          }
          if((currentTime / 1000) - (oldDateTime / 1000) > machine.comm_interval) 
          {
            On = false
          }

          return {
            yellow: machineOn,
            danger: !On,
            success: On,
            Off: Of
          }
        },
        
injectionChart(machineName) 
      {
      this.$router.push('/CompressionCharts/' + machineName)
      },

//SELECT ALL STATUS
  allstatus() {
       axios.get('https://m4a.ramacsystems.com/backend/mechines/machine_config/machine_config.php')
        //axios.get('http://m4abackend/mechines/machine_config/machine_config.php')
          .then((res) => {
            this.machine_status = res.data
          })
          .catch(function (error) {
              console.log(error)
          })
      },

    //MODAL FUNCTIONS
    openModal(position) {
     document.getElementById('myModal').style.display = 'block'
     this.machine_name = document.getElementById('machine_name').value = position
    },
    closeModal() { 
     document.getElementById('myModal').style.display = 'none'
    },

    machine_config(machine) {
      console.log(machine.position)
        let formData = new FormData()
        let action = 'statusUpdate'
        let fullname  = localStorage.getItem('fullname')
        let description = fullname+" Changed " +  machine.position + " state to " + machine.machine_state
        
        formData.append('action', action);
        formData.append('fullname', fullname)
        formData.append('description', description)
        formData.append('action', action);
        formData.append('machine_name', machine.position)
        formData.append('machine_state', machine.machine_state)
        axios(
          {
            url: 'https://m4a.ramacsystems.com/backend/mechines/machine_config/machine_config.php',
            //url: 'http://m4abackend/mechines/machine_config/machine_config.php',
            method: 'post',
            data: formData,
            config: { headers: { 'Content-Type' : 'multipart/formdata'}},
          })
          .then(() => {
            // this.closeModal()
            this.getMechines()
          })
      },

    product_config(machine) {
      console.log(machine.product)
        let formData = new FormData()
        let action = 'productUpdate'
        let fullname  = localStorage.getItem('fullname')
        let description = fullname+" Changed product for " +  machine.position + " to " + machine.product

        formData.append('action', action);
        formData.append('fullname', fullname)
        formData.append('description', description)
        formData.append('action', action);
        formData.append('machine_name', machine.position)
        formData.append('product', machine.product)
        axios(
          {
            url: 'https://m4a.ramacsystems.com/backend/mechines/machine_config/machine_config.php',
            //url: 'http://m4abackend/mechines/machine_config/machine_config.php',
            method: 'post',
            data: formData,
            config: { headers: { 'Content-Type' : 'multipart/formdata'}},
          })
          .then(() => {
            // this.closeModal()
            this.getMechines()
          })
      },
    
  }, 
}
</script>

<style scoped>
tbody tr:hover,
tbody tr:active {
  background-color: #A9A9A9;
}

.Off {
  background-color: red
  }

.machine_status._0 {
  background-color: orange;
  color: #fff;
  }

.machine_status._1 {
  background-color: green;
  }

.On {
  background-color: green;
  }

tr {
   line-height: 15px;
   min-height: 15px;
   height: 15px;
   word-wrap: break-word;
   max-width: 10px;
}

select {
   min-height: 10px;
   height: 15px;
   word-wrap: break-word;
   max-width: 80%;
}

.success {
  background-color: green;
    color: white;
  border: none;
}

.danger {
  background-color: red;
  color: white;
  border: none;
}

.yellow {
  background-color: orange;
  color: white;
  border: none;
}

.threshold {
  background-color: orange;
}

.success {
  background-color: green;
  color: white;
  border: none;
}

.danger {
  background-color: red;
}

.yellow {
  background-color: orange;
  color: white;
  border: none;
}

.threshold {
  background-color: orange;
}

#main_endDate,
#main_startDate,
#main_time,
#main_time1 {
  display: none;
}

.machine_name {
  word-wrap: break-word !important;
  width: 1px !important;
}

.close {
  margin-left: 50%;
  display: none;
}
.modal-title {
  margin-right: 30%;
}

#myModal {
  display: none;
}

#myModal {
  display: none; 
  position: fixed; 
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%;
  overflow: auto; 
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.4); 
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; 
  padding: 20px;
  border: 1px solid #888;
  width: 80%; 
}
</style>