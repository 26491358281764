import Vue from 'vue'
import App from './App.vue'
import router from './store/router'
import axios from 'axios'
import store from './store'
import 'bootstrap/dist/css/bootstrap.css'

Vue.config.productionTip = true
Vue.prototype.$http = axios
// const access_token = localStorage.getItem('access_token')
const current_date = new Date()
const current_date_time = current_date.getTime()

if(localStorage.getItem('access_token') != null && localStorage.getItem('userType') != 'viewer') {
    if (localStorage.getItem('dateTorken') < current_date_time) {
      localStorage.setItem('access_token', "")
      store.dispatch('destroyToken')
    }
}
// console.log(auth_data)
new Vue({
  axios,
  router,
  store,
  render: h => h(App),
}).$mount('#app')