import axios from 'axios'

export default {
    state: {
        machines: []
    },
    
    actions: {
        getAllMachines() {
            axios.get('http://m4a.ramacsystems.com/backend/mechines/addGet.php')
            .then((res) => {
                this.machines = res.data;
                this.compressionMolds = this.machines.filter(machine => machine.type == "Compression Mould");
                console.log(this.compressionMolds);
            })
            .catch(function (error) {
                console.log(error)
            })
        }
    },

    getters: {

    },

    mutations: {
    storeMachines(state, machines) {
        state.machines  = machines
    }
    }
}