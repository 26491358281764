<template>
  <div class="mechines bg-secondary">
  <h1 class="">Devices</h1>
  <table class="table table-hover table-dark" id="myTable">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">DeviceID</th>
      <th scope="col">WiFiRSSI</th>
      <th scope="col">WiFiSSID</th>
      <th scope="col">LastSeen <br>(Heartbeat)</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr @click="goToDetails(device.DeviceId)"
        v-for="(device, index) in deviceList" :key="device.SigfoxIDHex">
      <th scope="row">{{ index +1}}</th>
      <td style="text-transform: uppercase;">{{ device.DeviceId }}</td>
      <td>{{ device.WiFiRSSI }}</td>
      <td>{{ device.WiFiSSID }}</td>
      <td>{{ new Date(device.DateTimeUTC + "Z").toLocaleString('en-GB')}} </td>
      <td>
        <router-link to="/deviceComm" tag="i" class="fa fa-eye btn btn-info"></router-link>
      </td>
    </tr>
  </tbody>
</table>
  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
export default {
  data () {
     return {
       deviceList: [],
     }
  },

   mounted() {
     this.getAllDevices()
   },
   
methods: {
    //USE MOMENT
    getLocalTime(date) {
    return moment(date).format('YYYY-MM-DD HH:mm:ss')
  },
  getAllDevices() {
       axios.get('https://m4a.ramacsystems.com/backend/devices/GetDevices.php')
         .then((response) => {
          this.deviceList = response.data
       })
          .catch(error => console.log(error))
  },
    search() {
      var input, filter, table, tr, td, i, txtValue;
      input = document.getElementById("myInput");
      filter = input.value.toUpperCase();
      table = document.getElementById("myTable");
      tr = table.getElementsByTagName("tr");
      for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByTagName("td")[0];
        if (td) {
          txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";
          } else {
            tr[i].style.display = "none";
          }
        }       
      }
    },

    goToDetails(DeviceId) {
      this.$router.push('/deviceComm/' + DeviceId)
    }
 } 
}
</script>

<style scoped>
.mechines,
h1,
td,
th {
    color: #fff;
}

h1 {
    float: left;
    margin-left: 10px;
    text-transform: uppercase;
    font-size: 20px;
}

#addMechine {
  float: right;
  margin-right: 90px;
}

a {
  text-decoration: none;
}

nav {
	font-family: monospace;
}

li {
	color: #fff;
	display: block;
	float: left;
  margin-left: 0px;
	padding: 1px;
	position: relative;
	text-decoration: none;
  transition-duration: 0.5s;
}
  
li {
  color: #fff;
}


ul li ul{
	background: rgb(37, 37, 37);
	visibility: hidden;
  opacity: 0;
	position: absolute;
  transition: all 0.5s ease;
  margin-top: 1px;
  display: none;
}

ul li ul li a {
  color:#fff;
  float: right;
  margin-left: -10px;
}
ul li:hover > ul,
ul li ul:hover {
  visibility: visible;
  opacity: 1;
  display: block;
}

ul li ul li {
	clear: both;
  width: 100%;
}

#myInput {
  margin: 0px -15px 0px 57.8%;
  padding: 51x;
  border-radius: 4px;
  border: none;
}
</style>
