<template>
  <div class="mechines bg-secondary">
  <h1 class="">Main Factory Machines</h1>
  <router-link to="/AddMechine" class="btn btn-info" id="addMechine"  v-if="userType == 'Super Admin' || userType  == 'Admin'">Add Machine</router-link>
      <table class="table table-hover table-dark">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Machine</th>
          <th scope="col">Status</th>
          <th scope="col">RamacDevice</th>
          <th scope="col">LastSeen</th>
          <th scope="col"  v-if="userType == 'Super Admin' || userType  == 'Admin' || userType  == 'Manager'">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="machine in injections" :key="machine.id"  id="machine_row"  class="machine_status" :class="machine.status">
          <td scope="row">{{ machine.id }}</td>
          <td>{{ machine.machineName }}</td>
          <td>{{ machine.machine_state }}</td>
          <td>{{ machine.ramacDevice }}</td>
          <td>{{ new Date(machine.updatedDate + "Z").toLocaleString('en-GB') }}</td>
          <td>
            <i  v-if="userType == 'Super Admin' || userType  == 'Admin'" class="fa fa-eye btn btn-info" @click="gotToDetails(machine.machineName)"></i>
            <router-link
              v-if="userType == 'Super Admin' || userType  == 'Admin' || userType  == 'Manager'"
              tag="i" 
              :to="{ name: 'InjectionConfig', params: { machine }}" 
              class="btn btn-info fa fa-cog">
              <span class="tooltiptext">Machine configuration</span>
            </router-link>
            <router-link
               v-if="userType == 'Super Admin' || userType  == 'Admin' || userType  == 'Manager'"
              tag="i" 
              :to="{ name: 'EditInjection', params: { machine }}" 
              class="btn btn-info fa fa-pen">
              <span class="tooltiptext">Edit Machine</span>
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios'
export default {
data() {
  return {
    machines: [],
    injections: [],
    userType: ''
  }
},

mounted () {
  this.getMechines();
  this.getUserDetails()
},

methods: {
  //GET USER DETAILS
  getUserDetails() {
    let userDetails = localStorage.getItem('userType')

    this.userType = userDetails
  },
  getMechines() {
      axios.get('https://m4a.ramacsystems.com/backend/mechines/addGet.php')
        .then((res) => {
          this.machines = res.data
          this.injections = this.machines.filter(machine => machine.type == "Injection Mould");
        })
        .catch(function (error) {
            console.log(error)
        })
   },

 gotToDetails(machineName) {
     this.$router.push('/CompressionCharts/' + machineName)
     console.log(machineName)
   }
 } 
}
</script>

<style scoped>
.mechines,
h1,
td,
th {
    color: #fff;
}

h1 {
    float: left;
    margin-left: 10px;
    text-transform: uppercase;
    font-size: 20px;
}

#addMechine {
  float: right;
  margin-right: 90px;
}

i {
  margin: 0 10px;
}
button {
  margin: 10px;
}

.btn-info .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: darkgrey	;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 1px 0;
  margin-left: -70px;
  margin-top: 25px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.btn-info:hover .tooltiptext {
  visibility: visible;
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
}
</style>
