<template>
  <div id="" class="main">
    <!--LOADING CIRCLE-->
    <div id="loader"></div>
    <!-- FILTER -->
    <form>
        <input id="startDate" type="date"/> <input id="time" type="time" step="2" name="appt-time"/> <span class="to">TO</span>
        <input id="endDate" type="date"/> <input id="time1" type="time" step="2" name="appt-time"/> 
        <!-- <span class="limit_span">LIMIT</span><input id="limit" type="number" placeholder="Enter row limit."/> -->
       <button type="button" class="btn btn-info btn-sm" @click="getNewData()">GetData/Date</button>
    </form>
   <!-- CHARTS -->
      <div id="charts" class="flex-container" v-for="detail in machine_details" :key="detail.id">
        <div class="machine_charts bg-dark">
          <!-- PRODUCT GRAPH -->
        <div class="compressionMold">
          <div class="compress_heading">
            <h4>Material Extruded</h4>
          </div>
          <div class="compress_cotents">
            <canvas id="my-chart" class="bg-light" width="500" height="120"></canvas>
          </div>
        </div>
        </div>
        <div class="machine_details bg-dark">
          <div class="machine_details_heading">
            <h4 class="machine_name"> {{ detail.machineName }}</h4>
          </div>
          <div class="machine_details_cotents">
            <div class="row">
              <div class="col-sm-1"><h4>RAMAC:</h4></div>
              <div class="col-sm-1" style="text-transform: uppercase;"><p>{{detail.ramacDevice}}</p></div>
            </div>
            <!-- <div class="row">
              <div class="col-sm-1"><h4>Machine<br>LastState:</h4></div>
              <div class="col-sm-1" v-if="detail.status == 1 && new Date().getTime() / 60000 - new Date(detail.updatedDate).getTime() / 60000 < 15"><p class="on">RUNNING</p></div>
              <div class="col-sm-1" v-else-if="detail.status == 0 && new Date().getTime() / 60000 - new Date(detail.updatedDate).getTime() / 60000 < 15"><p class="off">NOPULSES</p></div>
              <div class="col-sm-1" v-else-if="new Date().getTime() / 60000 - new Date(detail.updatedDate).getTime() / 60000 > 15 && detail.status == 1"><p class="off">STOPPED</p></div>
            </div> COMMENTED THIS CODE BECAUSE I HAVE ADDED THE SENSOR STATUS -->
            <div class="row">
              <div class="col-sm-1"><h4>Sensor Status:</h4></div>
                <div class="col-sm-1" v-if="new Date().getTime() / 60000 - new Date(detail.updatedDate).getTime() / 60000 < detail.comm_interval"><p class="on">ON</p></div>
                <td v-else><p class="sensor_off">OFF</p></td>
            </div>
            <div class="row">
              <div class="col-sm-1"><h4>CURRUNT PRODUCT:</h4></div>
                <div class="" style="margin-left: 20px; margin-top: 10px;"><P>{{ curr_product }}</p></div>
            </div>
            <div class="row">
              <div class="col-sm-1"><h4>Total meters:</h4></div>
              <div class="col-sm-1"><p>{{ total }}</p></div>
            </div>
          </div>
        </div>
      </div>
      <!-- WEEKLY OR DAY DATA -->
      <div class="machine_weekly">
        <!-- FILTER -->
        <form class="machine_weekly_filter">
            <input id="weekly_startDate" type="date" @change="getMachineNewWeeklyData()"/>TO
            <input id="weekly_endDate" type="date" @change="getMachineNewWeeklyData()"/> 
          <json-excel :data="dataForExcel"  class="btn btn-info btn-sm">Export</json-excel>
        </form>
        <br>
        <br>
      <div class="machines bg-dark">
          <br>
            <h4 class="table_heading">Machines History/Week</h4>
            <table class="table table-hover table-dark">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th>Product</th>
                <th scope="col">Date</th>
                <th scope="col">Meters/Day</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(history, index) in machine_data" :key="history.DateTimeReceived">
                <td>{{ index +1 }}</td>
               <td>{{ history.curr_product }}</td>
                <td>{{ history.DateUTC }}</td>
                <td>{{ (history.deltaPulses / pulses_devider).toFixed(2) }}</td>
              </tr>
            </tbody>
          </table>
      </div>
    </div>
  </div>
</template>
<script>
import Chart from 'chart.js'
import JsonExcel from 'vue-json-excel'
import axios from 'axios'
import $ from 'jquery'
import ChartAnnotationsPlugin from 'chartjs-plugin-annotation'
Chart.plugins.register(ChartAnnotationsPlugin)
export default {
    data: () => {
    return {
      from_date: '',
      to_date: '',
      weekly_from_date: '',
      weekly_to_date: '',
      ramacDevice: '',
      chartData: [],
      chartData1: [],
      newChartData: [],
      deviceMessages: [],
      machineName: '',
      dates: [],
      dates1: [],
      newDates: [],
      timeEpoch: [],
      machine_details: [],
      machines: [],
      device_id: '',
      chart: '',
      chartNew: '',
      chartNew2: '',
      dataForExcel: [],
      dataForExcel1: [
      {
        Name: ""
      }
      ],
      Totalmeters: [],
      ctx: '',
      ctx2: '',
      startDate: '',
      converted: [],
      threshold: '',
      total: '',
      material_details: [],
      test: '',
      machine_data: [],
      pulses_devider: '',
      curr_product: 'Test',
      avg_time: ''
    };
  },

  components: {
    JsonExcel,
  },

  created() {
      $(document).ready(function(){
    })
  },

mounted() {
  this.getMechine();
 },
 
methods: {
 //GET MANCHINE
   getMechine() {
      axios.get('https://m4a.ramacsystems.com/backend/mechines/addGet.php')
        .then((res) => {
          this.mechines = res.data
          this.machine_details = this.mechines.filter(machine => machine.machineName == this.$route.params.machineName);
          this.threshold = this.machine_details[0].threshold
          this.ramacDevice = this.machine_details[0].ramacDevice
          this.pulses_devider = this.machine_details[0].pulseConfig
          this.getData();
          this.getMachineWeeklyData();
        })
        .catch(function (error) {
            console.log(error)
        })
   },
//API CALL AND DATA FORMART
    getData() {
      //CLEAR CHAT 
      if (this.ctx){
         this.chartData = [],
         this.dates = []
       }
      //FORMAT DATE FILTER
      var d = new Date();
      var endDate = new Date();
      endDate.setDate(endDate.getDate())
      let time = "00" + ":" + "00" + ":" + "00";
      let time1 = "23" + ":" + "59" + ":" + "00";
      document.getElementById('startDate').valueAsDate = d;
      document.getElementById('endDate').valueAsDate = endDate;
      document.querySelector('input[id="time"]').value = time;
      document.querySelector('input[id="time1"]').value = time1;
      this.to_date = new Date(document.getElementById("endDate").value + " " + document.getElementById('time1').value).toISOString();
      let machineName = this.$route.params.machineName
      this.from_date = new Date(document.getElementById('startDate').value + " "+ document.getElementById('time').value).toISOString();
      // var limit = document.getElementById("limit").value = 50
      var PacketType = 5 //I NEED TO DISSCUSS THIS WITH Q, HOW ARE WE GOING TO HANLE PULSE INPUT
       //API CALL
        // var x = document.getElementById("loader");
        //    x.className = "show";
        axios.get('https://m4a.ramacsystems.com/backend/mechines/machine_charts/extruders.php?params=', {
          params: {
             machineName,
             from_date: this.from_date,
             to_date: this.to_date,
            // limit: limit,
             PacketType: PacketType
          }
        })
        //RESPONSE AND RENDER CHART
        .then((response) => {
           this.deviceMessages = response.data
           console.log(this.deviceMessages)
           let pulses = this.deviceMessages.filter(machine => machine.PacketType == PacketType)
           this.material_details = this.deviceMessages.filter(machine => machine.PacketType == PacketType)
         //CURRENT PRODUCT AND COMMUNICATION
          if(this.deviceMessages.length == 0) {
            this.curr_product = ''
            this.comm_interval = ''
          } else {
            this.curr_product = this.deviceMessages[this.deviceMessages.length - 1]['curr_product']
            this.comm_interval = this.deviceMessages[this.deviceMessages.length - 1]['comm_interval']
          }

          //CHART DATA 
          for (let i = 0; i < pulses.length; i++) {
            if(pulses[i].deltaPulses < 0) {
              pulses[i].deltaPulses  = null
            }
            var meter = parseFloat((pulses[i].deltaPulses / this.machine_details[0].pulseConfig) / (pulses[i].differenceInSeconds / 60))
            this.Totalmeters = meter.toFixed(2)             
            this.chartData.push(this.Totalmeters)
            this.dates.push(new Date(pulses[i].DateTimeUTC + 'Z').toLocaleString('en-GB'))
           }
          //AVERAGE CAL !!NOTE MISSING THE FIRST VALUE ON THE AVD CAL. AVG OUT BY 0.5%
         // const d = this.deviceMessages.filter(diff => diff.DigitalState1Change != "");
          const d = this.deviceMessages;
          this.avg_time = (d.reduce((total, next) => total + next.differenceInSeconds / 1, 0) / d.length).toFixed(2);

          //TOTAL METERS
          this.total = pulses.reduce((sum, item) => sum + item.deltaPulses / this.machine_details[0].pulseConfig, 0).toFixed(2)
          setTimeout(() => {
              this.chartInt();
            }, 1000)
          // x.className = x.className.replace("show", "");
       })
          .catch(error => console.log(error))
     },

    //API CALL AND NEW DATA FORMART
    getNewData() {
          if (this.ctx){
                this.chartData = [],
                this.dates = []
                this.chartData1 = [],
                this.dates1 = []
              }
              this.ctx.data.labels.pop();
              this.ctx.data.datasets.forEach((dataset) => {
                  dataset.data.pop();
              });
          this.ctx.destroy(); 
          let to_date = new Date(document.getElementById("endDate").value + " " + document.getElementById('time1').value).toISOString();
          let machineName = this.$route.params.machineName
          let from_date = new Date(document.getElementById('startDate').value + " "+ document.getElementById('time').value).toISOString();
          //let limit = document.getElementById("limit").value
          var PacketType = 5 //I NEED TO DISSCUSS THIS WITH Q, HOW ARE WE GOING TO HANLE PULSE INPUT
            axios.get('https://m4a.ramacsystems.com/backend/mechines/machine_charts/extruders.php?params=', {
              params: {
                machineName,
                from_date,
                to_date,
                //limit,
                PacketType: PacketType
              }
            })
            //RESPONSE AND RENDER CHART
            .then((response) => {
              this.deviceMessages = response.data
              this.curr_product = this.deviceMessages[this.deviceMessages.length - 1]['curr_product']
              let pulses = this.deviceMessages.filter(machine => machine.PacketType == PacketType)
              this.material_details = this.deviceMessages.filter(machine => machine.PacketType == '1' && machine.DigitalState5 == '0')
              //CHART DATA 
              for (let i = 0; i < pulses.length; i++) {
                if(pulses[i].deltaPulses < 0) {
                  pulses[i].deltaPulses  = null
                }
                var meter = parseFloat((pulses[i].deltaPulses / this.machine_details[0].pulseConfig) / (pulses[i].differenceInSeconds / 60))
                this.Totalmeters = meter.toFixed(2)             
                this.chartData.push(this.Totalmeters)
                this.dates.push(new Date(pulses[i].DateTimeUTC + 'Z').toLocaleString('en-GB'))
              }
              //TOTAL METERS
              this.total = pulses.reduce((sum, item) => sum + item.deltaPulses / this.machine_details[0].pulseConfig, 0).toFixed(2)
              setTimeout(() => {
                  this.chartInt();
                }, 1000)
          })
        .catch(error => console.log(error))
     },

  //API CALL GET DATA PER WEEK
    getMachineWeeklyData() {
      var d = new Date();
      var endDate = new Date();
      endDate.setDate(endDate.getDate())
      d.setDate(d.getDate() - 7)
      let time1 = "23" + ":" + "59" + ":" + "59";
      document.getElementById('weekly_startDate').valueAsDate = d;
      document.getElementById('weekly_endDate').valueAsDate = endDate;
      document.querySelector('input[id="time1"]').value = time1;
      this.weekly_to_date = new Date(document.getElementById("weekly_endDate").value + " " + document.getElementById('time1').value);
      this.weekly_from_date = new Date(document.getElementById('weekly_startDate').value)
      let ramac_device = this.machine_details[0].DeviceId
      var PacketType = 5 //I NEED TO DISSCUSS THIS WITH Q, HOW ARE WE GOING TO HANLE PULSE INPUT
      axios.get('https://m4a.ramacsystems.com/backend/mechines/machine_charts/compressionWeekly.php?params=', {
        params: {
          ramac_device,
          from_date: this.weekly_from_date,
          to_date: this.weekly_to_date,
          PacketType: PacketType
        }
      })
      .then((response) => {
        this.machine_data_raw = response.data
        //CALCULATE REPORT DATA
        var result = Object.values(this.machine_data_raw.reduce(function(r, e) {
          var key = e.DateUTC + '|' + e.curr_product 
          e.deltaPulses = new Number(e.deltaPulses)
          if (!r[key]) r[key] = e;
          else {
            r[key].deltaPulses += e.deltaPulses;
          }
          return r;
        }, {}))

        this.machine_data = result
      //END CALCULATE REPORT DATA
      //DATA FOR THE EXCEL REPORT
        this.dataForExcel = this.machine_data.map(v =>
        ({
          Product: v.curr_product,
          DateTime: v.DateUTC,
          MetersPerDay: (v.deltaPulses / this.pulses_devider).toFixed(2),
        }))
      })
    },
    
  //API CALL GET DATA PER WEEK UPDATE
    getMachineNewWeeklyData() {
      let time1 = "23" + ":" + "59" + ":" + "59";
      document.querySelector('input[id="time1"]').value = time1;
      this.weekly_to_date = document.getElementById("weekly_endDate").value + " " + document.getElementById('time1').value;
      this.weekly_from_date = document.getElementById('weekly_startDate').value
      let ramac_device = this.machine_details[0].DeviceId
      var PacketType = 5 //I NEED TO DISSCUSS THIS WITH Q, HOW ARE WE GOING TO HANLE PULSE INPUT

      axios.get('https://m4a.ramacsystems.com/backend/mechines/machine_charts/compressionWeekly.php?params=', {
        params: {
          ramac_device,
          from_date: this.weekly_from_date,
          to_date: this.weekly_to_date,
          PacketType: PacketType
        }
      })
      .then((response) => {
        this.machine_data_raw = response.data
        //CALCULATE REPORT DATA
        var result = Object.values(this.machine_data_raw.reduce(function(r, e) {
          var key = e.DateUTC + '|' + e.curr_product 
          e.deltaPulses = new Number(e.deltaPulses)
          if (!r[key]) r[key] = e;
          else {
            r[key].deltaPulses += e.deltaPulses;
          }
          return r;
        }, {}))

        this.machine_data = result
        //END CALCULATE REPORT DATA
      //DATA FOR THE EXCEL REPORT
        this.dataForExcel = this.machine_data.map(v =>
        ({
          Product: v.curr_product,
          DateTime: v.DateUTC,
          MetersPerDay: (v.deltaPulses / this.pulses_devider).toFixed(2),
        }))
      })
    },
  //CHART INITIALIZE
   chartInt() {
    this.chartNew = document.getElementById('my-chart').getContext("2d")
    this.ctx = new Chart( this.chartNew, {
      type: 'line',
      data: {
        labels:  this.dates,
        datasets: [
          {
          label: this.$route.params.machineName,
          lineTension: 0,
          borderColor: '#0585e1',
          pointBackgroundColor: '#85998a',
          pointBorderColor: 'white',
          borderWidth: 1,
          backgroundColor: '#bdd9e4',
          data: this.chartData 
          },
        ]
      },
    options: {
        responsive: true,
            hover: { mode: null },
            scales: {
              xAxes: [{
                scaleLabel: {
                  display: true,
                  fontColor: '#000',
                  fontSize: '15',
                  labelString: 'FROM'+' '
                  +document.getElementById('startDate').value + " "+ document.getElementById('time').value
                  +' '+'TO'+' '
                  +document.getElementById("endDate").value + " " + document.getElementById('time1').value
                },
                ticks: {
                display: false,                
                major: {
                    fontStyle: 'bold',
                    fontColor: '#FF0000',
                  }
                }
              }],
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                  min: 0
                },
                scaleLabel: {
                  fontColor: '#000',
                  fontSize: '15',
                  display: true,
                  labelString: 'Meters Per Minute'
                }
              }]
              },
            annotation: {
                drawTime: 'afterDatasetsDraw',
                annotations: [{
                    id: 'hline',
                    type: 'line',
                    mode: 'horizontal',
                    scaleID: 'y-axis-0',
                    value: this.threshold,
                    borderColor: '#659EC7',
                    borderWidth: 3,
                }]
            }
         }
      });
    },
  }
}
</script>
<style scoped>
h1 {
    float: left;
    margin-left: 10px;
   color: black;
   text-transform: uppercase;
   font-size: 20px;
}

form {
   float: right;
   }
input {
  border-radius: 7px;
  border: solid 1px lightblue;
}
.btn {
  margin-left: 10px;
}

canvas {
  background-color: rgb(20, 43, 85);
  padding-right: 10px;
}

/* HEADINGS */
.compress_heading h4 {
  color: #fff;
}
.machine_details_heading h4 {
  color: #fff;
  text-align: center;
}

/*CONTAINTER STYLE */
.flex-container {
  display: flex;
  margin-top: 10px;
  width: 100%;
}

.flex-container  .machine_charts {
  margin: 10px;
  padding: 20px;
  width: 80%;
}

.flex-container  .machine_details {
  margin: 10px;
  padding: 20px;
  width: 20%;
}

.machine_details_cotents {
  background-color: #fff;
  /* height: 94%; */
}

.machine_details_cotents h4 {
  font-size: 15px;
  margin: 3px 20px 0 0;
  font-weight: bold;
}

h4 {
  text-transform: uppercase;
}
.machine_details_cotents p {
  margin: 0 20px 0 60px;
  font-weight: bold;
  }

  .on {
    color: green;
  }

  .off {
    color: rgb(255, 208, 0);
  }
  .sensor_off {
    color: red;
  }

.col-sm-1 .totalmeter {
  margin-top: 7px;
} 
.machine_name {
  word-wrap: break-word;
  width: 220px;
} 

.material_table {
  width: 100%;
}

small {
  padding-left: 10px;
}

.material_change {
  margin-top: 20px;
}

@media only screen and (max-width: 1920px) {
  /* #my-chart,
  #my-chart2
   {
    height: 120px !important;
  } */
  /* .main
   {
    height: 120px !important;
    width: 10% !important;
  } */
}

.col-sm-1 {
  margin-bottom: 5px;
}
.machines {
  width: 98.8%;
  margin-left: 10px;
}

/*CIRCLE CSS*/
#loader {
  visibility: hidden;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 90px;
  height: 90px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
	position: fixed;

  margin-left: 40%;
  margin-top: -2%;
}

  #loader.show {
	visibility: visible;
  }

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.to {
  color: #fff;
  padding-right: 5px;
}

.limit_span {
  color: #fff;
  padding-right: 5px;
  padding-left: 5px;
}

#limit {
  width: 129px;
}
</style>